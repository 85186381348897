import moment from "moment";
import { Fragment } from "react";
import React, { useState } from "react";
import { Col, Container, Row, Table, Form, Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import {
  getTodayDateISO,
  numberFormat,
  refreshPage,
  toastStyle,
  TokenExpiry,
} from "../../Helpers/Utils/Common";
import { searchProject } from "../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "../../Helpers/apiCalls/Manage/CustomerAPI";
import { dateFormat, getName } from "../../Helpers/Utils/Common";
import { getDistributorBilling, getOneDistributorBilling } from "../../Helpers/apiCalls/Distributor/DistributorApi";

export default function ViewDistributorBilling () {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentDate = new Date().toISOString().split("T")[0];
  const [inactive, setInactive] = useState(true);
  const [customersData, setCustomersData] = useState([]);
  const [allProjects, setAllProjects] = useState([])
  const [distributorDetails, setDistributorDetails] = useState({});
  const [clientDetails, setClientDetails] = useState([
    {
      customer_id: '',
      projects: [{ project_id: '', project_date: '' }],
    },
  ]);   

  async function handlePrint() {
    toast.loading("Printing printing details...", { style: toastStyle() });
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    refreshPage();
  }


  async function fetchCustomer() {
    setCustomersData([]);
    const response = await getAllCustomer();
    let isCustomer = [];

    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.data
    ) {

      let result = response.data.data.data.map((a) => {
        return {
          label: a.name,
          value: a.id,
        };
      });
      setCustomersData(result);
    }
  }

  async function fetchProjects () {
    const response = await searchProject({name:'',project_id:'',customer_id:''})
    if (response.data) {
      setAllProjects(response.data.data);
    }
  }

  async function getDistributorDetails () {
    const response = await getOneDistributorBilling({distributor_billing_id: id});
    if (response.data) {
      const res = response.data.data.map((row) => {
        var info = row;
        row.distributor.map((dist) => {
          info.distributor_name = dist.name;
          info.contact_person = dist.contact_person;
          info.contact_no = dist.contact_no;
          info.address = dist.address;
        })
        return info;
      })
      setDistributorDetails(res[0]);
      const inputArray = response.data.data[0].distributor_billing_entries;
    const mergedMap = new Map();

    inputArray.forEach(item => {
      const customerID = item.customer_id;
      const project = {
        project_id: item.project_id,
        project_name: item.project_name,
        project_date: item.project_date?moment(item.project_date).format("MMM DD, YYYY"):'',
        billing_date: moment(item.distributor_billing_entry_date).format("MMM DD, YYYY"),
        cost: item.amount,
      };
    
      if (mergedMap.has(customerID)) {
        mergedMap.get(customerID).projects.push(project);
      } else {
        mergedMap.set(customerID, {
          customer_id: customerID,
          customer_name: item.customer_name,
          projects: [project],
        });
      }
    });
    const mergedArray = Array.from(mergedMap.values());
    setClientDetails(mergedArray);
    }
    
    
  }

  React.useEffect(() => {
    fetchCustomer();
    fetchProjects();
    getDistributorDetails();
  }, []);

  const CustomerProjectsTable = () => {
    return (
        <Table className="align-middle">
          <thead>
            <tr>
              <th>Customer</th>
              <th>Project</th>
              <th>Project Date</th>
              <th>Billing Date</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            {clientDetails.map((client, customerIndex) => (
              <React.Fragment key={customerIndex}>
                {client.projects.map((project, projectIndex) => (
                  <tr key={projectIndex}>
                    {projectIndex === 0 && (
                      <td rowSpan={client.projects.length} className="w-30">{client.customer_name}</td>
                    )}
                    <td>{project.project_name}</td>
                    <td>{project.project_date}</td>
                    <td>{project.billing_date}</td>
                    <td>{project.cost}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
    );
  };

  return (
    <div>
        <div className="print-page page">
            <Navbar
                onCollapse={(inactive) => {
                    setInactive(inactive);
                }}
                active={"SALES"}
            />
        </div>
        <div
            className={`print-top container ${
                inactive ? "inactive" : "active"
            }`}
        >
            <div className="print-container px-5 py-2" id="printablediv">
                <div className="d-flex justify-content-center py-1">
                    <img src={cleanLogo} className="print-logo" />
                </div>
                <div className="d-flex justify-content-center py-1 mt-1">
                    <h5 className="print-shop-header">
                        TRIPLE K EXPRESSFOODS / 3K EXPRESSFOODS / CHK
                        BUSINESS VENTURES CORP
                    </h5>
                </div>

                {/* content */}
                <div className="print-body mt-5">
                    <Row>
                        <Col>
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Distributor:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {distributorDetails.distributor_name
                                        ? distributorDetails.distributor_name
                                        : ''}
                                </Col>
                            </div>
                            
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Contact Person:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {distributorDetails.contact_person}
                                </Col>
                            </div>
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Billing Date:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {moment(distributorDetails.billing_date).format("MMMM DD, YYYY")}
                                </Col>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Address:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {distributorDetails.address}
                                </Col>
                            </div>
                            <div className="d-flex my-2 align-items-center">
                                <Col xs={4} className="print-label">
                                    Contact No.:
                                </Col>
                                <Col xs={7} className="print-data">
                                    {distributorDetails.contact_no}
                                </Col>
                            </div>
                        </Col>
                    </Row>
                    <div className="d-flex mt-5 mb-2 justify-content-evenly">
                        <div className="print-table mt-3 mx-2">
                          <CustomerProjectsTable/>
                        </div>
                    </div>
                   
                    <Row className="print-grand-total my-3 d-flex justify-content-end me-5">
                        <Col xs={3} className="print-table-footer-label text-end">
                            GRAND TOTAL
                        </Col>
                        <Col
                            xs={3}
                            className="print-table-footer-data text-end"
                        >
                            PHP{" "}
                            {distributorDetails.grand_total
                                ? numberFormat(distributorDetails.grand_total)
                                : "0.00"}
                        </Col>
                    </Row>
                    <div className="print-signatures">
                        <span className="text-center text-uppercase print-label">
                            {getName()}
                        </span>
                        <span className="text-center text-uppercase print-label fw-bold">
                            {distributorDetails.prepared_by}
                        </span>
                    </div>
                    <div className="print-signatories pb-4 mb-4">
                        <span>Received by</span>
                        <span>Prepared by</span>
                    </div>
                </div>
            </div>

            {/* footer */}
            <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
                <button
                    className="button-secondary me-3"
                    onClick={() => navigate("/distributor_billings")}
                >
                    Close
                </button>
                <button
                    className="button-tertiary me-3"
                    onClick={() =>
                        navigate(
                           "/distributors/billing_statement", {state: {formPurpose: "edit", formOrigin: "billing", billing_id: id, distributor_id: ''}}
                        )
                    }
                >
                    Edit
                </button>
                <button
                    className="button-primary"
                    onClick={handlePrint}
                >
                    Print
                </button>
            </div>
        </div>
       
    </div>
);
}