import moment from "moment";
import React, { useState } from "react";
import { Container} from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import PaymentTable from "./PaymentTable";
import {
  isAdmin,
  numberFormat,
  refreshPage, 
  toastStyle,
  TokenExpiry,
} from "../../Helpers/Utils/Common";
import { getProjectInvoice } from "./../../Helpers/apiCalls/ProjectInvoice/ProjectInvoiceApi";
import "./ProjectInvoice.css";

export default function PrintProjectInvoice() {
  const { id } = useParams(); // id
  let navigate = useNavigate();

  const [inactive, setInactive] = useState(true);
  const [addDetails, setAddDetails] = useState([]);
  const [items, setItems] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState([]);
  async function fetchProjectInvoice() {
    setAddDetails({});
    setPaymentInfo([]);
    setItems([]);

    const response = await getProjectInvoice(id);

    if (response.error) {
      TokenExpiry(response);
    } else {
      var details = response.data.data[0];
      setAddDetails(details);
      setItems(details.project_invoice_items);

      var payment = details.project_invoice_payments?.map((data) => {
        if (data.paid_amount !== "0.00") {
          var info = data;
          info.paid_amount = numberFormat(data.paid_amount);
          if (data.payment_type === "check") {
            info.payment_type = data.payment_type + "-" + data.cheque_number;
          }
          setPaymentInfo((prev) => [...prev, info]);
          return info;
        }
      });
    }
    // handlePrint()
  }

  async function handlePrint() {
    toast.loading("Printing project invoice...", { style: toastStyle() });
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }
  const firstTable = () => {
    return (
      <div className="app-container">
        <table>
          <thead>
            <tr>
              <th
                className="text-left custom-td"
                colSpan="2"
                style={{ fontWeight: "bold" }}
              >
                Billing Details:
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className=" text-left custom-td">Company</td>
              <td className="print-data text-left custom-td">
                {addDetails.company}
              </td>
            </tr>
            <tr>
              <td className="text-left custom-td">Project Name</td>
              <td className="print-data text-left custom-td">
                {addDetails.project_name}
              </td>
            </tr>
            <tr>
              <td className=" text-left custom-td">Due Date</td>
              <td className="print-data text-left custom-td">
                {addDetails.due_date}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const secondTable = () => {
    return (
      <div className="app-container">
        <table>
          <thead>
            <tr>
              <th className="custom-td" style={{ fontWeight: "bold" }}>
                Product/Description
              </th>
              <th className="custom-td" style={{ fontWeight: "bold" }}>
                Quantity
              </th>
              <th className="custom-td" style={{ fontWeight: "bold" }}>
                Unit
              </th>
              <th className="custom-td" style={{ fontWeight: "bold" }}>
                Price
              </th>
              <th className="custom-td" style={{ fontWeight: "bold" }}>
                Total Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              return (
                <tr>
                  <td className="custom-desc-td">{item.item_name}</td>
                  <td className="custom-num-td">{item.qty}</td>
                  <td className="text-lowercase custom-desc-td">{item.unit}</td>
                  <td className="custom-num-td" style={{ color: "black" }}>
                    {numberFormat(item.price)}
                  </td>
                  <td className="custom-num-td" style={{ color: "black" }}>
                    {numberFormat(item.subtotal)}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={5}>
                <div>{/* <hr /> */}</div>
              </td>
            </tr>
            <tr>
              <td colSpan={3}></td>
              <td className="text-end">Sub Total</td>
              <td className="text-right" style={{ fontSize: "12px" }}>
                PHP{" "}
                {addDetails.subtotal
                  ? numberFormat(addDetails.subtotal)
                  : "0.00"}
              </td>
            </tr>
            <tr>
              <td colSpan={3}></td>
              <td className="text-end">Service Fee</td>
              <td className="text-right" style={{ fontSize: "12px" }}>
                PHP{" "}
                {addDetails.service_fee
                  ? numberFormat(addDetails.service_fee)
                  : "0.00"}
              </td>
            </tr>
            <tr>
              <td colSpan={3}></td>
              <td className="text-end">Other Fees</td>
              <td className="text-right" style={{ fontSize: "12px" }}>
                PHP{" "}
                {addDetails.delivery_fee
                  ? numberFormat(addDetails.delivery_fee)
                  : "0.00"}
              </td>
            </tr>
            <tr>
              <td colSpan={3}></td>
              <td
                className="text-end"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              >
                GRAND TOTAL
              </td>
              <td
                className="text-right"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              >
                PHP{" "}
                {addDetails.grand_total
                  ? numberFormat(addDetails.grand_total)
                  : "0.00"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  function sentenceCase(str) {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    refreshPage();
  }
  React.useEffect(() => {
    fetchProjectInvoice();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"FRANCHISE"}
        />
      </div>
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-3 py-2" id="printablediv">
          <div
            className={
              addDetails.fs_status === "invoiced"
                ? "text-end print-header d-flex flex-column"
                : "text-start print-header d-flex flex-column"
            }
          ></div>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-left">
                <img
                  src={cleanLogo}
                  className="print-logo"
                  alt="Logo"
                  style={{ width: "200px", height: "70px" }}
                />
              </div>
              <div className="text-end">
                <div>
                  <span
                    style={{
                      fontSize: "40px",
                      color: "#744aa4",
                      fontWeight: "bold",
                    }}
                  >
                    INVOICE
                  </span>
                </div>
              </div>
            </div>

            <div className="text-left">
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  MYT SoftDev Solutions, Inc.
                </span>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  NO. {id}
                </span>
              </div>
            </div>

            <div className="text-left">
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: "12px" }}>
                  301 The Greenery, Pope John Paul II Ave.
                </span>
                <span className="text-uppercase" style={{ fontSize: "12px" }}>
                  {moment(addDetails.added_on).format("MMMM DD, yyyy")}.
                </span>
              </div>
            </div>

            <div style={{ fontSize: "12px" }}>Cebu City, 6000 Cebu</div>
          </div>

          <div className="d-flex mt-5 mb-2 justify-content-evenly">
            <div className="print-table mt-3 mx-2">{firstTable()}</div>
          </div>
          <div className="d-flex mt-5 mb-2 justify-content-evenly">
            <div className="print-table mt-3 mx-2">{secondTable()}</div>
          </div>
          <div className="d-flex mt-3 mb-2 justify-content-evenly">
            <div className="print-table mt-3 mx-3">
              REMARKS:{" "}
              {addDetails && addDetails.remarks !== null
                ? sentenceCase(addDetails.remarks === "undefined" ? "" : addDetails.remarks )
                : ""}
            </div>
          </div>
          <div
            className="text-left"
            style={{ fontSize: "12px", padding: "30px" }}
          >
            <span>
              For check payment, deposit payment to:
              <div>Bank Name: Banco de Oro (BDO) Cebu HM Tower</div>
              <div>Account Number: 013030014422</div>
              <div>Account Name: MYT SoftDev Solutions, Inc.</div>
            </span>
          </div>

          <div>
      
          </div>
        </div>

        <Container
          fluid
          className="PI-payment-info-wrapper mt-5 py-3 px-3 edit-form"
        >
          <h5 className="PI-payment-info">PAYMENT HISTORY</h5>
          <div className="sales-tbl justify-content-center">
            <PaymentTable
              tableHeaders={[
                "PYMT DATE",
                "INV NO.",
                // "PARTICULARS",
                "TYPE",
                "PAID AMT",
                "DEPOSIT DATE",
                "DEPOSITED TO",
                "REMARKS",
              ]}
              headerSelector={[
                "payment_date",
                "invoice_no",
                // "particulars",
                "payment_type",
                "paid_amount",
                "deposit_date",
                "to_bank_name",
                "remarks",
              ]}
              tableData={paymentInfo}
            />
          </div>
        </Container>

        {/* footer */}
        <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <button
            className="button-secondary me-3"
            onClick={() => navigate("/projectinvoice")}
          >
            Close
          </button>
          {addDetails.payment_status === "open_bill" && isAdmin() ? (
            <button
              className="button-tertiary me-3"
              onClick={() => navigate("/projectinvoice/edit/" + id)}
            >
              Edit
            </button>
          ) : (
            ""
          )}
          <button className="button-primary" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </div>
  );
}
