import {handleValidationChange} from '../CommonValidation';

export const validateExpenseTypes= (data, setIsError) => {

    var isValid = true;
    var isValidName = true;
    var isValidAddress = true;
    var isValidPhoneNumber = true;
    var isValidDescription = true;


    // if(data.address === "") {
    //     handleValidationChange("address", true, setIsError);
    //     isValidAddress = false;
    // } else {
    //     handleValidationChange("address", false, setIsError);
    //     isValidAddress = true;
    // }

    
   if(data.name === "") {
        handleValidationChange("name", true, setIsError);
        isValidName = false;
    } else {
        handleValidationChange("name", false, setIsError);
        isValidName = true;
    }


    // if(data.phone_number === "") {
    //     handleValidationChange("phone_number", true, setIsError);
    //     isValidPhoneNumber = false;
    // } else {
    //     handleValidationChange("phone_number", false, setIsError);
    //     isValidPhoneNumber = true;
    // }

    if(data.description === "") {
        handleValidationChange("description", true, setIsError);
        isValidDescription = false;
    } else {
        handleValidationChange("description", false, setIsError);
        isValidDescription = true;
    }


    



    isValid = isValidName   && isValidPhoneNumber && isValidAddress
    //  && isValidDescription



    return isValid
  
}