import {handleValidationChange} from '../CommonValidation';

export const validateUsers = (data, setIsError) => {
    var isValid = true;

    if(data.first_name === "") {
        handleValidationChange("first_name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("first_name", false, setIsError);
    }
    
    if(data.last_name === "") {
        handleValidationChange("last_name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("last_name", false, setIsError);
    }

    if(data.username === "") {
        handleValidationChange("username", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("username", false, setIsError);
    }

    if(data.password === "") {
        handleValidationChange("password", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("password", false, setIsError);
    }

    if(data.confirm_password === "") {
        handleValidationChange("confirm_password", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("confirm_password", false, setIsError);
    }

    return isValid
  
}