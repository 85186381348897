import React, { useState, useRef, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
//components
import Table from "../../Components/TableTemplate/Table";
import Navbar from "../../Components/Navbar/Navbar";
import DeleteModal from "../../Components/Modals/DeleteModal";
import AddModal from "../../Components/Modals/AddModal";
import EditModal from "../../Components/Modals/EditModal";
import ViewModal from "../../Components/Modals/ViewModal";

//css
import "./Manage.css";
import "../../Components/Navbar/Navbar.css";

import { getType, toastStyle } from "../../Helpers/Utils/Common";
import toast from "react-hot-toast";
import {
  getAllSuppliers,
  createSupplier,
  deleteSupplier,
  updateSupplier,
  searchSupplier,
} from "../../Helpers/apiCalls/suppliersApi";
import { validateSuppliers } from "../../Helpers/Validation/Manage/SuppliersValidation";
import InputError from "../../Components/InputError/InputError";

export default function Suppliers() {
  const accountType = getType();
  const [inactive, setInactive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [filterConfig, setFilterConfig] = useState({
    tab: "MYT ERP",
  });
  const handleTabSelect = (tabKey) => {
    setFilterConfig((prev) => {
      return { ...prev, tab: tabKey };
    });
  };
  const isInitialMount = useRef(true);
  const filterConfigKey = "manage-suppliers-filterConfig";

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      setFilterConfig((prev) => {
        if (window.localStorage.getItem(filterConfigKey) != null) {
          handleTabSelect(
            JSON.parse(window.localStorage.getItem(filterConfigKey)).tab
          );
          return JSON.parse(window.localStorage.getItem(filterConfigKey));
        } else {
          return { ...prev };
        }
      });
    } else {
      window.localStorage.setItem(
        filterConfigKey,
        JSON.stringify(filterConfig)
      );
    }
  }, [filterConfig]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewSupplierModal, setShowViewSupplierModal] = useState(false);
  const [showEditSupplierModal, setShowEditSupplierModal] = useState(false);
  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);

  //API
  const [tableData, setTableData] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  const [supplierData, setSupplierData] = useState({});
  const [selectedID, setSelectedID] = useState("");
  const [supplierDetails, setSupplierDetails] = useState({
    trade_name: "",
    trade_address: "",
    bir_name: "",
    bir_address: "",
    tin: "",
    terms: "",
    requirements: "",
    phone_no: "",
    email: "",
    contact_person: "",
    bank_primary: "",
    bank_alternate: "",
    payee: "",
  });

  //ERROR HANDLING
  const [isError, setIsError] = useState({
    trade_name: false,
    trade_address: false,
    contact_person: false,
    phone_no: false,
    payee: false,
    email: false,
  });

  //onEdit
  function handleOnEdit() {
    setShowViewSupplierModal(false);
    setShowEditSupplierModal(true);
  }

  //DELETE or REMOVE SUPPLIER
  function handleDeleteSupplier() {
    removeSupplier(selectedID);
  }
  //DROPDOWN
  function handleSelectChange(e, row) {
    setSelectedID(row.id);
    setSupplierData(row);
    if (e.target.value === "delete-supplier") {
      setShowDeleteModal(true);
    } else if (e.target.value === "edit-supplier") {
      setShowEditSupplierModal(true);
    } else if (e.target.value === "view-supplier") {
      setShowViewSupplierModal(true);
    } else {
      setShowDeleteModal(true);
    }
  }

  // const filteredData = tableData.filter(item =>
  //   item.trade_name.toLowerCase().includes(searchText.toLowerCase()) ||
  //   item.trade_address.toLowerCase().includes(searchText.toLowerCase())
  // );

  function ActionBtn(row) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action form-select"
        id={row.id}
        onChange={(e) => handleSelectChange(e, row)}
        value={""}
      >
        <option defaulValue selected hidden>
          Select
        </option>
        <option value="view-supplier" className="color-options">
          View
        </option>
        <option value="edit-supplier" className="color-options">
          Edit
        </option>
        {accountType === "admin" && (
          <option value="delete-supplier" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

  function resetValues() {
    setSupplierDetails({});
    setSupplierData({});
  }

  //API CALL
  async function fetchAllSuppliers() {
    setShowLoader(true);
    setTableData([]);
    const response = await searchSupplier(searchText);
    if (response.response) {
      var suppliersList = response.response.data;
      setTableData(suppliersList);
    }

    setShowLoader(false);
  }

  async function handleSaveSupplier() {
    if (validateSuppliers(supplierDetails, setIsError)) {
      setIsClicked(true);
      const response = await createSupplier(supplierDetails);
      if (response.response) {
        toast.success("Successfully created supplier!", {
          style: toastStyle(),
        });
        setShowAddSupplierModal(false);
        fetchAllSuppliers();
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        });
      }
      setIsClicked(false);
    } else {
      toast.error("Error Creating New Supplier", {
        style: toastStyle(),
      });
    }
  }

  async function handleEditSupplier() {
    if (validateSuppliers(supplierData, setIsError)) {
      const response = await updateSupplier(supplierData);
      if (response.response) {
        toast.success(response.response.response, {
          style: toastStyle(),
        });
        setShowEditSupplierModal(false);
        fetchAllSuppliers();
      } else {
        toast.error("Error Updating New Supplier", {
          style: toastStyle(),
        });
      }
    }
  }

  async function removeSupplier(id) {
    const response = await deleteSupplier(id);
    if (response.data) {
      toast.success("Successfully deleted user!", {
        style: toastStyle(),
      });
      setShowDeleteModal(false);
      resetValues();
      fetchAllSuppliers();
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      });
    }
  }

  //EDIT OR UPDATE FORWARDER
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setSupplierData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  React.useEffect(() => {
    fetchAllSuppliers();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"MANAGE"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> SUPPLIERS </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search Company Name..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  fetchAllSuppliers();
                }
              }}
              className="search-bar"
            />
            <button
              className="add-btn"
              eventKey="MYT ERP"
              onClick={() => setShowAddSupplierModal(true)}
            >
              Add
            </button>
          </Col>
        </Row>
        <div className="tab-content">
          <Table
            tableHeaders={
              accountType === "admin"
                ? [
                    "COMPANY NAME",
                    "ADDRESS",
                    "CONTACT PERSON",
                    "PHONE NUMBER",
                    "COMPANY EMAIL",
                    "ACTIONS",
                  ]
                : [
                    "COMPANY NAME",
                    "ADDRESS",
                    "CONTACT PERSON",
                    "PHONE NUMBER",
                    "COMPANY EMAIL",
                  ]
            }
            headerSelector={[
              "trade_name",
              "trade_address",
              "contact_person",
              "phone_no",
              "email",
            ]}
            tableData={tableData}
            ActionBtn={(row) => ActionBtn(row)}
            showLoader={showLoader}
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="supplier"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleDeleteSupplier}
      />
      <AddModal
        title={`SUPPLIER`}
        show={showAddSupplierModal}
        onHide={() => {
          setShowAddSupplierModal(false);
          resetValues();
        }}
        onSave={handleSaveSupplier}
        isClicked={isClicked}
        data={supplierDetails}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row mt-0">
            <Col xs={6}>
              BIR NAME
              <Form.Control
                type="text"
                name="bir_name"
                value={supplierDetails.bir_name}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              TRADE NAME <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_name"
                value={supplierDetails.trade_name}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
              <InputError
                isValid={isError.trade_name}
                message={"Trade name is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={6}>
              BIR-REGISTERED ADDRESS
              <Form.Control
                type="text"
                name="bir_address"
                value={supplierDetails.bir_address}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              TRADE ADDRESS
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="text"
                name="trade_address"
                value={supplierDetails.trade_address}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {/* <InputError
                isValid={isError.trade_address}
                message={"Trade address is required"}
              /> */}
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              TIN NUMBER{" "}
              <Form.Control
                type="text"
                name="tin"
                value={supplierDetails.tin}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              BIR NUMBER
              <Form.Control
                type="text"
                name="bir_number"
                className="nc-modal-custom-input"
                value={supplierDetails.bir_number}
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row-details">
              TERM (DAYS)
              <Form.Control
                type="text"
                name="terms"
                value={supplierDetails.terms}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              CONTACT PERSON
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="text"
                name="contact_person"
                value={supplierDetails.contact_person}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {/* <InputError
                isValid={isError.contact_person}
                message={"Contact person is required"}
              /> */}
            </Col>
            <Col xs={4}>
              PHONE NUMBER
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="text"
                name="phone_no"
                value={supplierDetails.phone_no}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {/* <InputError
                isValid={isError.phone_no}
                message={"Phone number is required"}
              /> */}
            </Col>
            <Col xs={4}>
              PAYEE
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="email"
                name="payee"
                value={supplierDetails.payee}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
              {/* <InputError
                isValid={isError.payee}
                message={"Payee is required"}
              /> */}
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              PRIMARY BANK NAME
              <Form.Control
                type="text"
                name="bank_primary"
                value={supplierDetails.bank_primary}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NO.
              <Form.Control
                type="number"
                name="primary_account_no"
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NAME
              <Form.Control
                type="text"
                name="primary_account_name"
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              ALTERNATE BANK NAME
              <Form.Control
                type="text"
                name="bank_alternate"
                value={supplierDetails.bank_alternate}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NO.
              <Form.Control
                type="number"
                name="alternate_account_no"
                className="nc-modal-custom-input"
                value={supplierDetails.alternate_account_no}
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NAME
              <Form.Control
                type="text"
                name="alternate_account_name"
                className="nc-modal-custom-input"
                value={supplierDetails.alternate_account_name}
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              COMPANY EMAIL
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="email"
                name="email"
                value={supplierDetails.email}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {/* <InputError
                isValid={isError.email}
                message={"Email is required"}
              /> */}
            </Col>
          </Row>
        </div>
      </AddModal>

      <EditModal
        title="SUPPLIER"
        show={showEditSupplierModal}
        onHide={() => {
          setShowEditSupplierModal(false);
          resetValues();
        }}
        onSave={handleEditSupplier}
        data={supplierData}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row mt-0">
            <Col xs={6}>
              BIR NAME
              <Form.Control
                type="text"
                name="bir_name"
                value={supplierData.bir_name}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE NAME <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_name"
                value={supplierData.trade_name}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
                required
              />
              <InputError
                isValid={isError.trade_name}
                message={"Trade name is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={6}>
              BIR-REGISTERED ADDRESS
              <Form.Control
                type="text"
                name="bir_address"
                value={supplierData.bir_address}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE ADDRESS
              <Form.Control
                type="text"
                name="trade_address"
                value={supplierData.trade_address}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin"
                value={supplierData.tin}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              BIR NUMBER
              <Form.Control
                type="text"
                name="bir_number"
                value={supplierData.bir_number}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              CONTACT PERSON<label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="contact_person"
                value={supplierData.contact_person}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
              />
              <InputError
                isValid={isError.contact_person}
                message={"Contact person is required"}
              />
            </Col>
            <Col xs={4}>
              PHONE NUMBER<label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="phone_no"
                value={supplierData.phone_no}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
              />
              <InputError
                isValid={isError.phone_no}
                message={"Phone number is required"}
              />
            </Col>
            <Col xs={4}>
              PAYEE <label className="badge-required">{` *`}</label>
              <Form.Control
                type="email"
                name="payee"
                value={supplierData.payee}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isError.payee}
                message={"Payee is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              PRIMARY BANK NAME
              <Form.Control
                type="text"
                name="bank_primary"
                value={supplierData.bank_primary}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NO.
              <Form.Control
                type="text"
                name="primary_account_no"
                value={supplierData.primary_account_no}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NAME
              <Form.Control
                type="text"
                name="primary_account_name"
                value={supplierData.primary_account_name}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              ALTERNATE BANK NAME
              <Form.Control
                type="text"
                name="bank_alternate"
                value={supplierData.bank_alternate}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NO.
              <Form.Control
                type="text"
                name="alternate_account_no"
                value={supplierData.alternate_account_no}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NAME
              <Form.Control
                type="text"
                name="alternate_account_name"
                value={supplierData.alternate_account_name}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4} className="nc-modal-custom-row-details">
              TERM (DAYS)
              <Form.Control
                type="text"
                name="terms"
                value={supplierData.terms}
                onChange={(e) => handleEditChange(e)}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row-details">
              COMPANY EMAIL
              {/* <label className="badge-required">{` *`}</label> */}
              <Form.Control
                type="email"
                name="email"
                className="nc-modal-custom-input-edit"
                value={supplierData.email}
                onChange={(e) => handleEditChange(e)}
              />
              {/* <InputError
                isValid={isError.email}
                message={"Email is required"}
              /> */}
            </Col>
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        show={showViewSupplierModal}
        onHide={() => setShowViewSupplierModal(false)}
        onEdit={handleOnEdit}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title-forwarder-details">
            SUPPLIER DETAILS
          </span>
          <Row className="nc-modal-custom-row mt-3">
            <Col xs={6}>
              BIR NAME
              <Form.Control
                type="text"
                name="bir_name"
                value={supplierData.bir_name}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE NAME <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_name"
                value={supplierData.trade_name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={6}>
              BIR-REGISTERED ADDRESS
              <Form.Control
                type="text"
                name="bir_address"
                value={supplierData.bir_address}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={6}>
              TRADE ADDRESS
              <Form.Control
                type="text"
                name="trade_address"
                value={supplierData.trade_address}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin"
                value={supplierData.tin}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              BIR NUMBER
              <Form.Control
                type="text"
                name="bir_number"
                value={supplierData.bir_number}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row-details">
              TERM (DAYS)
              <Form.Control
                type="text"
                name="terms"
                value={supplierData.terms}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              CONTACT PERSON
              <Form.Control
                type="text"
                name="contact_person"
                value={supplierData.contact_person}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
            <Col xs={4}>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="phone_no"
                value={supplierData.phone_no}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
            <Col xs={4}>
              PAYEE <label className="badge-required">{` *`}</label>
              <Form.Control
                type="email"
                name="payee"
                value={supplierData.payee}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              PRIMARY BANK NAME
              <Form.Control
                type="text"
                name="bank_primary"
                value={supplierData.bank_primary}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NO.
              <Form.Control
                type="text"
                name="primary_account_no"
                value={supplierData.primary_account_no}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NAME
              <Form.Control
                type="text"
                name="primary_account_name"
                value={supplierData.primary_account_name}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              ALTERNATE BANK NAME
              <Form.Control
                type="text"
                name="bank_alternate"
                value={supplierData.bank_alternate}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NO.
              <Form.Control
                type="text"
                name="alternate_account_no"
                value={supplierData.alternate_account_no}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NAME
              <Form.Control
                type="text"
                name="alternate_account_name"
                value={supplierData.alternate_account_name}
                disabled
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4} className="nc-modal-custom-row-details">
              COMPANY EMAIL
              <Form.Control
                type="email"
                name="email"
                className="nc-modal-custom-input-edit"
                value={supplierData.email}
                disabled
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
    </div>
  );
}
