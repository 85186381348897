import {handleValidationChange} from '../CommonValidation';

export const validatePayment = (data, setIsError) => {
 
    var isValid = true;

    // Sure na validation here for cash payment in project sales invoice
    if(data.payment_type === "") {
        handleValidationChange("payment_type", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("payment_type", false, setIsError);
    }

    if(data.payment_date === "") {
        handleValidationChange("payment_date", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("payment_date", false, setIsError);
    }

    if(data.invoice_no === "" || data.invoice_no === undefined ) {
        handleValidationChange("invoice_no", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("invoice_no", false, setIsError);
    }

    if(data.paid_amount === "" || data.paid_amount === undefined ) {
        handleValidationChange("paid_amount", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("paid_amount", false, setIsError);
    }

    if(data.deposit_date === "" || data.deposit_date === undefined ) {
        handleValidationChange("deposit_date", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("deposit_date", false, setIsError);
    }

    if(data.to_bank_id === "" || data.to_bank_id === undefined) {
        handleValidationChange("to_bank_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("to_bank_id", false, setIsError);
    }


    // Additional validations if payment type is cheque

    if (data.payment_method === "check" && (data.bank_name === ""||data.bank_name===undefined)) {
        handleValidationChange("bank_name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("bank_name", false, setIsError);
    }

    if(data.payment_type === "check" && (data.check_number === "" || data.check_number === undefined)) {
        handleValidationChange("check_number", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("check_number", false, setIsError);
    }

    if(data.payment_type === "check" && (data.check_date === "" || data.check_date === undefined)) {
        handleValidationChange("check_date", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("check_date", false, setIsError);
    }

    // Additional validation if others and payment

    if(data.payment_type === "others" && (data.reference_number === "" || data.reference_number === undefined)) {
        handleValidationChange("reference_number", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("reference_number", false, setIsError);
    }


    return isValid
  
}