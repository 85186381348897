import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";
// api
import { getAllProjects } from "../../Helpers/apiCalls/Purchases/purchaseOrderApi";
// assets & styles
import {
  getTodayDateISO,
  getTodayDateISOFormat,
  numberFormat,
  toastStyle,
} from "../../Helpers/Utils/Common";
import trash from "./../../Assets/Images/trash.png";
import Navbar from "../../Components/Navbar/Navbar";
import { Fragment } from "react";
import { updateSalesInvoice } from "../../Helpers/apiCalls/franchiseeApi";
import { getAllBanks } from "../../Helpers/apiCalls/banksAPi";
import InputError from "../../Components/InputError/InputError";
import ReactLoading from "react-loading";
import {
  createProjectInvoice,
  updateProjectInvoice,
  getProjectInvoice,
  recordStatusProjectInvoice,
} from "./../../Helpers/apiCalls/ProjectInvoice/ProjectInvoiceApi";
import { validateProjectInvoice } from "./../../Helpers/Validation/Project/ProjectValidation";
import moment from "moment";

function FormProjectInvoice({ add, edit, process, createinvoice }) {
  let navigate = useNavigate();
  const currentYear = moment().format("YYYY");
  const [inactive, setInactive] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [addDetails, setAddDetails] = useState({
    project_id: location.state ? location.state.row.id : "",
    invoice_date: add ? getTodayDateISO() : "",
    project_name: location.state ? location.state.row.name : "",
    project_date: location.state
      ? getTodayDateISOFormat(location.state.row.project_date)
      : "",
    due_date: "",
    address: location.state ? location.state.row.address : "",
    company: location.state ? location.state.row.company : "",
    project_id_value: {
      value: location.state ? location.state.row.id : "",
      label: location.state ? location.state.row.name : "",
    },
    vat_type: "vat-ex",
  });

  // DATA HANDLERS
  const [banks, setBanks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [particulars, setParticulars] = useState([
    {
      item_name: "",
      qty: 0,
      unit: "",
      price: 0,
      item_info: "",
      invoice_no: "",
      remarks: "",
    },
  ]);
  const [fileList, setFileList] = useState([]);

  //ERROR HANDLING
  const [isError, setIsError] = useState({
    project_id: false,
    project_date: false,
    invoice_date: false,
    address: false,
    items: false,
    item_info: false,
    invoice_no: false,
  });

  function handleAddDetailsChange(e, search) {
    const { name, value } = e.target;
    var net;
    var withold;
    var twelve;
    var grandtotal;
    if (search) {
      setAddDetails((prevState) => ({
        ...prevState,
        [e.for]: e.value,
        [e.select_value]: {
          for: e.for,
          label: e.label,
          value: e.value,
        },
        project_date: e.project_date,
        address: e.address,
        company: e.company,
        invoice_no: e.invoice_no,
      }));
      setIsChanged(true);
      var info = {
        qty: 1,
        price: parseFloat(e.balance),
        total: e.balance,
        subtotal: e.balance,
      };
      setParticulars([info]);
      setTimeout(() => setIsChanged(false), 10);
    } else if (e.target.value === "vat-in") {
      net = parseFloat(addDetails.subtotal ? addDetails.subtotal : 0) / 1.12;
      withold = parseFloat(net ? net : 0) * 0.02;
      twelve =
        parseFloat(addDetails.subtotal ? addDetails.subtotal : 0) -
        parseFloat(net ? net : 0);
      grandtotal =
        parseFloat(addDetails.subtotal ? addDetails.subtotal : 0) -
        parseFloat(withold ? withold : 0);

      setAddDetails((prevState) => ({
        ...prevState,
        [name]: value,
        vat_net: net,
        vat_twelve: twelve,
        withholding_tax: withold,
        grand_total: grandtotal,
      }));
    } else if (e.target.value === "vat-ex") {
      net = 0;
      withold = 0;
      twelve = 0;
      grandtotal = parseFloat(addDetails.subtotal ? addDetails.subtotal : 0);

      setAddDetails((prevState) => ({
        ...prevState,
        [name]: value,
        vat_net: net,
        vat_twelve: twelve,
        withholding_tax: withold,
        grand_total: grandtotal,
      }));
    } else {
      const newList = { ...addDetails };
      const { name, value } = e.target;
      newList[name] = value;
      if (name === "service_fee" || name === "delivery_fee") {
        setIsChanged(!isChanged);
      }
      setAddDetails(newList);
    }
  }

  /**
   *  ORDER ITEMS
   */
  const [orderItems, setOrderItems] = useState([]);

  async function handleSubmitInvoice() {
    if (
      !isClicked &&
      validateProjectInvoice(addDetails, particulars, setIsError)
    ) {
      setIsClicked(true);
      const response = await createProjectInvoice(
        addDetails,
        particulars,
        fileList
      );
      if (response.data) {
        if (response.data.status === "error") {
          toast.error(response.data.response, {
            style: toastStyle(),
          });
          // setTimeout(() => refreshPage(), 1000);
        } else if (response.data.status === "success") {
          toast.success("Successfully created project invoice", {
            style: toastStyle(),
          });
          setTimeout(
            () =>
              navigate(
                "/projectinvoice/print/" + response.data.project_invoice_id,
                "_blank"
              ),
            1000
          );
        }
      } else {
        var errMsg = response.error.response;
        toast.error(errMsg, { style: toastStyle() });
        setIsClicked(false);
      }
      setIsClicked(false);
    } else {
      toast.error("Please fill in all fields", { style: toastStyle() });
    }
  }

  async function handleUpdatePI() {
    setIsClicked(true);
    const response = await updateProjectInvoice(addDetails, particulars);
    if (response.data?.status === "success") {
      toast.success("Successfully updated sales invoice", {
        style: toastStyle(),
      });
      setTimeout(() => navigate("/projectinvoice/print/" + id), 1000);
    } else if (response.error) {
      toast.error("Error updating sales invoice", {
        style: toastStyle(),
      });
      setIsClicked(false);
    }
    setIsClicked(false);
  }

  async function handleProcess() {
    const response = await updateSalesInvoice(
      id,
      orderItems,
      particulars,
      addDetails
    );
    if (response.data) {
      toast.success("Successfully processed quotation", {
        style: toastStyle(),
      });
      setTimeout(() => navigate("/projectinvoice"), 1000);
    } else if (response.error) {
      toast.error("Error updating sales invoice", {
        style: toastStyle(),
      });
    }
    setIsClicked(false);
  }

  async function handleCreateInvoiceBtn() {
    setIsClicked(true);
    const response = await updateProjectInvoice(addDetails, particulars);
    if (response.data) {
      const record = await recordStatusProjectInvoice(id, "invoiced");
      if (record.data) {
        toast.success("Successfully created invoice", {
          style: toastStyle(),
        });
        setTimeout(() => navigate("/projectinvoice/print/" + id), 1000);
      }
    } else if (response.error) {
      toast.error("Error updating project invoice", {
        style: toastStyle(),
      });
      setIsClicked(false);
    }
    setIsClicked(false);
  }

  const handleSubmit = () => {
    if (add) handleSubmitInvoice();
    else if (edit) handleUpdatePI();
    else if (process) handleProcess();
    else if (createinvoice) handleCreateInvoiceBtn();
  };

  /** FOR EDIT - Fetch Sales Invoice Details */
  async function fetchProjectInvoice() {
    const response = await getProjectInvoice(id);
    if (response.data) {
      var SI = response.data.data[0];
      SI.project_id_value = {
        label: SI.project_name,
        value: SI.project_id,
      };
      if (process || createinvoice) {
        SI.payment_type = "cash";
        SI.paid_amount = "0";
        SI.payment_date = getTodayDateISO();
        SI.deposit_date = getTodayDateISO();
        SI.due_date = getTodayDateISOFormat(SI.due_date);
      }
      setAddDetails(SI);
      setParticulars(SI.project_invoice_items);
    }
  }

  function handleCreatePayment(e) {
    const paymentInfo = { ...addDetails };
    const { name, value, id } = e.target;
    if (name === "service_fee" || name === "delivery_fee") {
      const paymentEdit = { ...addDetails };
      paymentEdit[name] = value;
      setAddDetails(paymentEdit);
      setIsChanged(!isChanged);
    }

    if (name === "to_bank_id" || name === "from_bank_id") {
      paymentInfo[name] = value;
    } else {
      paymentInfo[name] = value;
    }

    setAddDetails(paymentInfo);
  }

  function handleAddNewRow() {
    const newItem = {
      item_id: { value: null },
      name: "",
      qty: "",
      unit_discount: "0",
      unit: "",
      price: "",
      type: "",
      se_id: "0",
    };
    setParticulars((oldItems) => [...oldItems, newItem]);
  }

  function handleDelRow(id) {
    setIsChanged(true);

    const newList = [...particulars];
    newList.splice(id, 1);
    setParticulars(newList);

    setTimeout(() => setIsChanged(false), 1);
  }

  function handleParticularChange(e, id) {
    setIsChanged(true);
    const { name, value } = e.target;
    var temp = particulars;
    if (name === "qty" || name === "price") {
      temp.map((item, index) => {
        if (index === id) {
          item[name] = value;
          item.subtotal = item.qty * item.price;
          item.total = item.qty * item.price;

          return item;
        }
      });
    } else {
      temp.map((item, index) => {
        if (index === id) {
          item[name] = value;
          return item;
        }
      });
    }
    setParticulars(temp);
    setTimeout(() => setIsChanged(false), 1);
  }

  // FETCH API
  async function fetchAllProjects() {
    setProjects([]);
    const response = await getAllProjects();
    if (response && response.data && response.data.data) {
      let result = response.data.data.map((a) => {
        var info = a;
        // a.name =  "project_id";
        a.for = "project_id";
        a.select_value = "project_id_value";
        a.label = a.name;
        a.value = a.id;
        return info;
      });
      setProjects(result);
    }
  }

  async function fetchBanks() {
    const response = await getAllBanks();
    if (response.error) {
    } else {
      setBanks(response.data.data);
    }
  }

  // FOR DYNAMIC CALCULATION
  useEffect(() => {
    var tempServiceFee = addDetails.service_fee
      ? parseFloat(addDetails.service_fee)
      : 0;
    var tempDeliveryFee = addDetails.delivery_fee
      ? parseFloat(addDetails.delivery_fee)
      : 0;

    var _subtotal = particulars
      .map((item) => parseFloat(item.subtotal))
      .reduce((a, b) => a + b, 0);

    var _grandTotal = _subtotal + tempServiceFee + tempDeliveryFee;
    var _itemsTotal = _subtotal;
    setAddDetails((prev) => {
      return {
        ...prev,
        subtotal: _itemsTotal.toFixed(2),
        grand_total: _grandTotal.toFixed(2),
      };
    });
  }, [isChanged]);

  const [desData, setDesData] = useState([]);
  const [qtyData, setQtyData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [priceData, setPriceData] = useState([]);

  // DATA FETCHING
  useEffect(() => {
    if (edit || process || createinvoice) {
      fetchProjectInvoice();
    }
    fetchAllProjects();
    fetchBanks();
  }, []);

  useEffect(() => {
    particulars.map((data) => {
      setDesData(data.item_name);
      setQtyData(data.qty);
      setUnitData(data.unit);
      setPriceData(data.price);
    });
  }, [addDetails]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`container ${inactive ? "inactive" : "active"}`}>
        {/* header */}
        <div className="d-flex justify-content-between align-items-center my-3 pb-4">
          <h1 className="page-title mb-0">
            {add ? "ADD PROJECT INVOICE" : "EDIT PROJECT INVOICE"}
          </h1>
        </div>

        {/* content */}
        <div className="edit-form">
          {/* FRANCHISEE SALES INVOICE DETAILS */}
          <Fragment>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">
                  Project Name
                  <span className="color-red"> *</span>
                </span>
              </Col>
              <Col xs={3}>
                <span className="edit-label">
                  Project Date
                  <span className="color-red"> *</span>
                </span>
              </Col>
              <Col xs={3}>
                <span className="edit-label">
                  Invoice Date
                  <span className="color-red"> *</span>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select Project..."
                  selected={addDetails.project_id_value}
                  options={projects}
                  onChange={(e) => {
                    setAddDetails((prev) => ({
                      ...prev,
                      project_id_value: e.id,
                      project_id: e.id,
                      project_name: e.name,
                      project_date: e.project_date,
                      company: e.company,
                      address: e.address,
                      invoice_no: `${currentYear}-${
                        parseFloat(e.id) < 10
                          ? `000${e.id}`
                          : parseFloat(e.id) > 99
                          ? `0${e.id}`
                          : `00${e.id}`
                      }`,
                    }));
                  }}
                />
                <InputError
                  isValid={isError.project_id}
                  message={"Project name is required"}
                />
              </Col>
              <Col xs={3}>
                <Form.Control
                  disabled
                  type="date"
                  name="project_date"
                  className="nc-modal-custom-text"
                  defaultValue={
                    location.state
                      ? getTodayDateISOFormat(location.state.row.project_date)
                      : addDetails.project_date
                  }
                />
                <InputError
                  isValid={isError.project_date}
                  message={"Project date is required"}
                />
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="date"
                  name="invoice_date"
                  className="nc-modal-custom-text"
                  defaultValue={addDetails.invoice_date}
                  onChange={(e) => handleAddDetailsChange(e)}
                />
                <InputError
                  isValid={isError.invoice_date}
                  message={"Invoice date is required"}
                />
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">
                  Address
                  {/* <span className="color-red"> *</span> */}
                </span>
              </Col>
              <Col>
                <span className="edit-label">
                  Company
                  <span className="color-red"> *</span>
                </span>
              </Col>
              <Col xs={3}>
                <span className="edit-label">
                  Due Date
                  {/* <span className="color-red"> *</span> */}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  disabled
                  type="text"
                  name="address"
                  defaultValue={
                    location.state
                      ? location.state.row.address
                      : addDetails.address
                  }
                  className="nc-modal-custom-text"
                />
                {/* <InputError
                  isValid={isError.address}
                  message={"Address is required"}
                /> */}
              </Col>
              <Col>
                <Form.Control
                  disabled
                  type="text"
                  name="company"
                  defaultValue={
                    location.state
                      ? location.state.row.company
                      : addDetails.company
                  }
                  className="nc-modal-custom-text"
                />
                <InputError
                  isValid={isError.company}
                  message={"Company is required"}
                />
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="date"
                  name="due_date"
                  className="nc-modal-custom-text"
                  defaultValue={addDetails.due_date}
                  onChange={(e) => handleAddDetailsChange(e)}
                />
                {/* <InputError
                  isValid={isError.due_date}
                  message={"Due date is required"}
                /> */}
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">Remarks</span>
              </Col>
              <Col>
                <span className="edit-label">
                  Invoice No.
                  <span className="color-red"> *</span>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  name="remarks"
                  defaultValue={
                    addDetails.remarks === "undefined" ? "" : addDetails.remarks
                  }
                  className="nc-modal-custom-text"
                  onChange={(e) => handleAddDetailsChange(e)}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="invoice_no"
                  className="nc-modal-custom-text"
                  // value={id}
                  value={addDetails.invoice_no}
                  disabled
                  // defaultValue={addDetails.invoice_no}
                  // onChange={(e) => handleAddDetailsChange(e)}
                />
                {/* <InputError
                  isValid={isError.invoice_no}
                  message={"invoice no. is required"}
                /> */}
              </Col>
            </Row>
          </Fragment>

          <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">
              Particulars<span className="color-red"> *</span>
            </span>
            <div className="edit-purchased-items">
              {particulars.length !== 0 ? (
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th className="color-gray">description</th>
                        <th className="color-gray">quantity</th>
                        <th className="color-gray">unit</th>
                        <th className="color-gray">unit price</th>
                        <th className="color-gray">amount</th>
                        <th className="color-gray">actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {particulars.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <Form.Control
                                type="text"
                                name="item_name"
                                value={item.item_name}
                                onChange={(e) =>
                                  handleParticularChange(e, index)
                                }
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                name="qty"
                                value={item.qty}
                                onChange={(e) =>
                                  handleParticularChange(e, index)
                                }
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="unit"
                                value={item.unit}
                                onChange={(e) =>
                                  handleParticularChange(e, index)
                                }
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                name="price"
                                // value={parseFloat(item.price).toFixed(2)}
                                value={item.price}
                                onChange={(e) =>
                                  handleParticularChange(e, index)
                                }
                              />
                            </td>
                            <td className="color-green">
                              {item.qty && item.price
                                ? numberFormat(item.price * item.qty)
                                : "0.00"}
                            </td>
                            <td className="text-center">
                              <img
                                src={trash}
                                onClick={() => handleDelRow(index)}
                                className="cursor-pointer"
                                alt=""
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <InputError
                    isValid={isError.item_info}
                    message={"Please make sure all fields are filled"}
                  />
                </>
              ) : (
                <div className="entries-not-found">
                  There is no particulars recorded yet.
                </div>
              )}
              <InputError
                isValid={isError.items}
                message={"Please add at least 1 particular"}
              />
            </div>
          </Row>

          {/* ADD ITEM BUTTON */}
          <Row className="pt-3 PO-add-item">
            <Button type="button" onClick={() => handleAddNewRow()}>
              Add Item
            </Button>
          </Row>

          {/* SUBTOTAL, FREIGHT COST, DISCOUNT, & GRAND TOTAL */}
          <Fragment>
            <Row className="align-right pt-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">Subtotal</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle">PHP</span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label align-middle">
                  {numberFormat(
                    addDetails?.subtotal === "NaN" ? 0 : addDetails?.subtotal
                  )}
                </span>
              </Col>
            </Row>
            <Row className="align-right pt-3">
              <Col className="text-end">
                <Form.Check
                  inline
                  label="VAT Ex"
                  name="vat_type"
                  type="radio"
                  value="vat-ex"
                  checked={addDetails.vat_type === "vat-ex"}
                  onClick={(e) => {
                    handleAddDetailsChange(e);
                  }}
                />
                <Form.Check
                  inline
                  label="VAT In"
                  name="vat_type"
                  type="radio"
                  value="vat-in"
                  checked={addDetails.vat_type === "vat-in"}
                  onClick={(e) => {
                    handleAddDetailsChange(e);
                    // setAddDetails((prev) => ({...prev, vat_type: "vat-in"}))
                  }}
                />
              </Col>
            </Row>
            `
            {addDetails.vat_type === "vat-in" && (
              <>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">12% VAT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {addDetails.vat_twelve
                        ? numberFormat(
                            parseFloat(addDetails.vat_twelve).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">Net of VAT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {addDetails.vat_net
                        ? numberFormat(
                            parseFloat(addDetails.vat_net).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">WHT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {addDetails.withholding_tax
                        ? numberFormat(
                            parseFloat(addDetails.withholding_tax).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>
              </>
            )}
            <Row className="align-right py-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray grand-total-text">
                  Grand Total
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle grand-total-text">
                  PHP
                </span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label align-middle grand-total-text text-end">
                  {numberFormat(
                    addDetails?.grand_total === "NaN"
                      ? 0
                      : parseFloat(addDetails.grand_total).toFixed(2)
                  )}
                </span>
              </Col>
            </Row>
            {(process || createinvoice) &&
              addDetails.payment_status !== "closed_bill" && (
                <>
                  <Row className="align-right pt-3">
                    <Col xs={2} className="text-end">
                      <span className="edit-label color-gray">
                        Payment Type
                      </span>
                    </Col>
                    <Col xs={1} className="text-end"></Col>
                    <Col xs={3}>
                      <Form.Check
                        inline
                        label="Cash"
                        name="payment_type"
                        value="cash"
                        type="radio"
                        defaultChecked={addDetails.payment_type === "cash"}
                        onClick={(e) => {
                          handleCreatePayment(e);
                        }}
                      />
                      <Form.Check
                        inline
                        label="Check"
                        name="payment_type"
                        type="radio"
                        value="check"
                        defaultChecked={addDetails.payment_type === "check"}
                        onClick={(e) => {
                          handleCreatePayment(e);
                        }}
                      />
                      <Form.Check
                        inline
                        label="Others"
                        name="payment_type"
                        value="others"
                        defaultChecked={addDetails.payment_type === "others"}
                        type="radio"
                        onClick={(e) => {
                          handleCreatePayment(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="align-right pt-3">
                    <Col xs={2} className="text-end">
                      <span className="edit-label color-gray">
                        Paid Amount
                        <span className="color-red"> *</span>
                      </span>
                    </Col>
                    <Col xs={1} className="text-end">
                      <span className="edit-label align-middle">PHP</span>
                    </Col>
                    <Col xs={3}>
                      <Form.Control
                        type="number"
                        name="paid_amount"
                        defaultValue={addDetails.paid_amount}
                        className="align-middle nc-modal-custom-text"
                        onChange={(e) => handleCreatePayment(e)}
                      />
                    </Col>
                  </Row>
                </>
              )}
          </Fragment>

          {(process || createinvoice) &&
            addDetails.payment_status !== "closed_bill" && (
              <>
                {addDetails.payment_type === "cash" && (
                  <>
                    <div className="mt-5"></div>
                    <hr />
                    <div className="payment-header-wrapper mb-5">
                      <h5 className="payment-header">Payment Details</h5>
                    </div>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Payment Date
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="date"
                          name="payment_date"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.payment_date}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.payment_date}
                          message={"Payment date is required"}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Invoice Number
                          <label className="badge-required">{` *`}</label>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="invoice_no"
                          className="nc-modal-custom-text"
                          value={addDetails.invoice_no}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.invoice_no}
                          message={"Invoice Number is required"}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Deposit Date
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="date"
                          name="deposit_date"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.deposit_date}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Deposited To
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Select
                          type="text"
                          name="to_bank_id"
                          className="nc-modal-custom-text"
                          value={addDetails.to_bank_id}
                          onChange={(e) => handleCreatePayment(e)}
                        >
                          <option value="">Select a bank...</option>
                          {banks.map((data) => {
                            return (
                              <option value={data.id}>{data.bank_name}</option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Term (days)
                          <span className="edit-optional px-2">(Optional)</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="term_day"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.term_day}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Payment Remarks
                          <span className="edit-optional px-2">(Optional)</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          as="textarea"
                          name="payment_remarks"
                          defaultValue={addDetails.payment_remarks}
                          className="nc-modal-custom-text"
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {/* CHECK PAYMENT DETAILS */}
                {addDetails.payment_type === "check" && (
                  <>
                    <div className="mt-5"></div>
                    <hr />
                    <div className="payment-header-wrapper mb-5">
                      <h5 className="payment-header">Payment Details</h5>
                    </div>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Payment Date
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                      <Col>
                        <span className="edit-label">
                          Invoice Number
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                      <Col>
                        <span className="edit-label">
                          Check Date
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="date"
                          name="payment_date"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.payment_date}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="invoice_no"
                          className="nc-modal-custom-text"
                          value={addDetails.invoice_no}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.invoice_no}
                          message={"Invoice Number is required"}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="date"
                          name="cheque_date"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.cheque_date}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.cheque_date}
                          message={"Check date is required"}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Bank Name
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                      <Col>
                        <span className="edit-label">
                          Check Number
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="bank_name"
                          className="nc-modal-custom-text"
                          value={addDetails.bank_name}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.bank_name}
                          message={"Bank is required"}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="cheque_number"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.cheque_number}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.cheque_number}
                          message={"Check number is required"}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Deposited To
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Select
                          type="text"
                          name="to_bank_id"
                          className="nc-modal-custom-text"
                          value={addDetails.to_bank_id}
                          onChange={(e) => handleCreatePayment(e)}
                        >
                          <option value="">Select a bank...</option>
                          {banks.map((data) => {
                            return (
                              <option value={data.id}>{data.bank_name}</option>
                            );
                          })}
                        </Form.Select>
                        <InputError
                          isValid={isError.to_bank_id}
                          message={"Check number is required"}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Term (days)
                          <span className="edit-optional px-2">(Optional)</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="term_day"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.term_day}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <Row className="edit-label">
                          Payment Remarks
                          <Row className="edit-optional px-2">(Optional)</Row>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          as="textarea"
                          name="payment_remarks"
                          defaultValue={addDetails.payment_remarks}
                          className="nc-modal-custom-text"
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {/* OTHERS PAYMENT DETAILS */}
                {addDetails.payment_type === "others" && (
                  <>
                    <div className="mt-5"></div>
                    <hr />
                    <div className="payment-header-wrapper mb-5">
                      <h5 className="payment-header">Payment Details</h5>
                    </div>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Payment Date
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="date"
                          name="payment_date"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.payment_date}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Invoice Number
                          <label className="badge-required">{` *`}</label>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="invoice_no"
                          className="nc-modal-custom-text"
                          value={addDetails.invoice_no}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Reference Number
                          <label className="badge-required">{` *`}</label>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="reference_number"
                          className="nc-modal-custom-text"
                          value={addDetails.reference_number}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                        <InputError
                          isValid={isError.reference_number}
                          message={"Reference number is required"}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">Payment Description</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="payment_remarks"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.payment_remarks}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Deposited To
                          <span className="color-red"> *</span>
                        </span>
                      </Col>
                      <Col>
                        <span className="edit-label">
                          Term (days)
                          <span className="edit-optional px-2">(Optional)</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Select
                          type="text"
                          name="to_bank_id"
                          className="nc-modal-custom-text"
                          value={addDetails.to_bank_id}
                          onChange={(e) => handleCreatePayment(e)}
                        >
                          <option value="">Select a bank...</option>
                          {banks.map((data) => {
                            return (
                              <option value={data.id}>{data.bank_name}</option>
                            );
                          })}
                        </Form.Select>
                        <InputError
                          isValid={isError.to_bank_id}
                          message={"Deposited to is required"}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="term_day"
                          className="nc-modal-custom-text"
                          defaultValue={addDetails.term_day}
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-2">
                      <Col>
                        <span className="edit-label">
                          Payment Remarks
                          <span className="edit-optional px-2">(Optional)</span>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          as="textarea"
                          name="payment_remarks"
                          defaultValue={addDetails.payment_remarks}
                          className="nc-modal-custom-text"
                          onChange={(e) => handleCreatePayment(e)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}

          {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
          <div className="d-flex justify-content-end pt-5 pb-3">
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            {isClicked ? (
              <div className="button-primary d-flex justify-content-center">
                <ReactLoading
                  type="bubbles"
                  color="#FFFFFF"
                  height={50}
                  width={50}
                />
              </div>
            ) : addDetails.project_id_value === "" ||
              addDetails.project_date === "" ||
              addDetails.invoice_date === "" ||
              addDetails.company === "" ||
              desData.length === 0 ||
              qtyData.length === 0 ||
              unitData.length === 0 ||
              priceData.length === 0 ? (
              <button type="button" className="button-primary" disabled>
                Submit
              </button>
            ) : (
              <button
                type="button"
                className="button-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

FormProjectInvoice.defaultProps = {
  add: false,
  edit: false,
};

export default FormProjectInvoice;
