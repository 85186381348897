import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
// import DatePicker from "react-datepicker";
import { DatePicker } from "antd";
import Moment from "moment";
import { Line, Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
  
import NoDataImg from "./../../../Assets/Images/no-data-dog.png"
import "../ExpensesReport.css"
import { searchExpenseByType } from './../../../Helpers/apiCalls/ExpensesReports/ExpenseReportApi';
import { getAllExpenseType } from "../../../Helpers/apiCalls/expensetypesApi";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import moment from "moment";

export default function ExpenseByType() {
    let navigate = useNavigate();
    const dateToday = moment();
    const firstDayOfMonth = dateToday.startOf("month").format('YYYY-MM-DD');
    const lastDayOfMonth = dateToday.endOf("month").format('YYYY-MM-DD');

    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({
        date_from: firstDayOfMonth,
        date_to: lastDayOfMonth,
    })
    const [noData, setNoData] = useState(false);
    const [expenseType, setExpenseType] = useState([]);
    const [amount, setAmount] = useState([]);
    const [background, setBackground] = useState([]);
    const [border, setBorder] = useState([]);
    const [allExpenseTypes, setAllExpenseTypes] = useState([]);
    const [filteredTypes, setFilteredTypes] = useState("");


    const data = {
        labels: expenseType,
        datasets: [
          {
            label: "Expense By Type",
            data: amount,
            fill: true,
            backgroundColor: background,
            borderColor: border,
          },
        ]
    };

    function setBg() {
        const randomColor = Math.floor(Math.random()*16777215).toString(16);
        var color = "#" + randomColor;
        return color
    }

    async function fetchData() {
        const response = await searchExpenseByType(filterConfig, filteredTypes);

        if (response.error) {
            setExpenseType([])
            setAmount([])
            setNoData(true)
        } else {
            setExpenseType(response.data.expense_type)
            setAmount(response.data.expense_total)
            setNoData(false)
            var bg = response.data.expense_type.map((date)=>{
                var color = setBg()
                return color
            })
            setBackground(bg)
            setBorder(bg)
        }
    }

    
    const handleTypeFilter = (e) => {
        if(e.value === ""){
            setFilteredTypes("")
            if(filteredTypes === ""){
                fetchData()
            }
        }else{
            setFilteredTypes(e.name)
        }
    }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        ArcElement,
        Title,
        Tooltip,
        Legend
    );

    React.useEffect(() => {
        fetchData();
    }, [filterConfig, filteredTypes]);

    React.useEffect(() => {
        async function fetchAllExpenseTypes () {
            const response = await getAllExpenseType();
            if (response.data) {
                const res = response.data.map((row) => {
                    var info = row;
                    info.value = row.id;
                    info.label = row.name;
                    return info;
                })
                setAllExpenseTypes(res);
                // setFilteredTypes(res);
            }
        }

        fetchAllExpenseTypes()
    }, []);

    const generatePdf = () => {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const text = "EXPENSE REPORT.";
            const pdf = new jsPDF();
            pdf.text(text, 5, 5)
            pdf.addImage(imgData, 'PNG',15,15,180,60);
            pdf.save("Expensebytype.pdf");
        });
    }

    const options = {
        plugins: {
            datalabels: {
            formatter: (value, context) => {
                const dataset = context.chart.data.datasets[0];
                const total = dataset.data.reduce((acc, data) => acc + data, 0);
                const percentage = ((value / total) * 100).toFixed(2) + "%";
                return percentage;
            },
            color: '#fff', // Set the color of the labels
            anchor: 'end', // Set the position of the labels
            align: 'end', // Set the alignment of the labels
            display: 'auto', // Set to 'auto' for automatic display
            },
        },
    };

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EXPENSES REPORT"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-4">
                    <Col xs={6}>
                        <h1 className="page-title"> EXPENSE REPORT </h1>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                        <button className="add-btn text-center" onClick={generatePdf}>Export to PDF</button>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end mb-3">
                        <div className="justify-content-center align-items-center ">
                            <button
                                type="button"
                                className="button-primary "
                                onClick={() => navigate("/expensereport/" + Moment(filterConfig.date_from).format("MM-DD-YYYY") + "/" + Moment(filterConfig.date_to).format("MM-DD-YYYY"))}
                            >
                                View
                            </button>
                        </div>
                    </Col>
                </Row>

                <div className="tab-content">
                    {/* filters */}
                    <div className="my-2 px-2 PO-filters d-flex">
                        <span className="me-4 align-middle mt-2 ps-label">
                            Filter By Date:
                        </span>

                        <span className="me-4 align-middle mt-2 ps-label">
                            From:
                        </span>
                        <DatePicker
                            placeholder={filterConfig.date_from}
                            name="date_from"
                            placeholderText={Moment().format("MM/DD/YYYY")}
                            onChange={(date) => {
                                if (date) {
                                    setFilterConfig((prev) => {
                                        return { ...prev, date_from: date.format('YYYY-MM-DD') };
                                    });
                                }
                            }}
                            // fixedHeight
                            className="PI-date-btn me-3 form-control"
                            // showYearDropdown
                            // dateFormatCalendar="MMMM"
                            // yearDropdownItemNumber={20}
                            // scrollableYearDropdown
                        />

                        <span className="me-4 align-middle mt-2 ps-label">
                            To:
                        </span>
                        <DatePicker
                            placeholder={filterConfig.date_to}
                            name="date_to"
                            placeholderText={Moment().format("MM/DD/YYYY")}
                            onChange={(date) => {
                                if (date) {
                                    setFilterConfig((prev) => {
                                        return { ...prev, date_to: date.format('YYYY-MM-DD') };
                                    });
                                }
                            }}
                            // fixedHeight
                            className="PI-date-btn me-3 form-control"
                            // showYearDropdown
                            // dateFormatCalendar="MMMM"
                            // yearDropdownItemNumber={20}
                            // scrollableYearDropdown
                        />
                        <span className="me-4 ms-4 align-middle mt-2 ps-label">
                            Expense Type:
                        </span>
                        <Select
                            name="type"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Select Expense Type..."
                            value={allExpenseTypes.map((item) => item.id === filterConfig.expense_type)}
                            options={[{label: "All", value:''}, ...allExpenseTypes]}
                            onChange={handleTypeFilter}
                        />
                    </div>

                    {/* <div className=" PO-filters d-flex justify-content-center">
                        <span className="me-4 ml-4 mt-2 ps-label">
                            Total Usage Quantity: {numberFormat(totalQuantity)}
                        </span>
                    </div> */}

                    <div className="below d-flex justify-content-center mb-5" id="divToPrint">
                        {
                            !noData ? (
                                <>
                                    {/* <div className="col-1 d-flex justify-content-right"></div> */}
                                    <div className="col-6 d-flex justify-content-right">
                                        <Pie className="chart-size ms-5" data={data} options={options}/>
                                    </div>
                                    <div className="col-6 d-flex justify-content-left me-5">
                                        <Line className="chart-size ms-5" data={data} options={options}/>
                                    </div>
                                    {/* <div className="col-1 d-flex justify-content-right"></div> */}
                                </>
                            ) : (
                                <div className="no-data-cont">
                                    <img src={NoDataImg} alt="no data found" className="no-data-small"/>
                                    <span>Uh Oh! No data found.</span>
                                </div>
                            )
                        }
                    </div>
                    <div className="mb-2" />
                </div>
            </div>

        </div>
    );
}


