import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import AdjustmentTable from "./../../Inventory/Adjustment/AdjustmentTable";
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry,
    getTodayDateISO
} from "../../../Helpers/Utils/Common";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";

import { getAllFranchisee } from "../../../Helpers/apiCalls/franchiseeApi";
import { getAllSales } from "../../../Helpers/apiCalls/Reports/BillingFeeSales";
import { searchProject } from './../../../Helpers/apiCalls/Manage/Projects';
import { getAllCustomer } from './../../../Helpers/apiCalls/Manage/CustomerAPI';
import { getAllSuppliers } from './../../../Helpers/apiCalls/suppliersApi';
import { getAllExpenseType } from './../../../Helpers/apiCalls/expensetypesApi';
import { searchPayablesAgingReport } from './../../../Helpers/apiCalls/ExpensesReports/ExpenseReportApi';
import ExportPDF from "../../../Components/Export/ExportPdf";

export default function PayablesAgingReport() {
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();
    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({
        payment_status: "paid"
    })
    const[pdfData, setPdfData] = useState([])
    const [franchisees, setFranchisees] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [allData, setAllData] = useState([]);
    const [totalPayables, setTotalPayables] = useState([]);
    const [marketingFee, setMarketingFee] = useState([]);
    const [invoiceSales, setInvoiceSales] = useState([]);
    const [totalSales, setTotalSales] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [expenseTypes, setExpenseTypes] = useState([]);
    const excelHeaders = [
        { label: "Supplier", key: "supplier_name" },
        { label: "Current", key: "cur" },
        { label: "1-30 Days", key: "one_to_thirthy" },
        { label: "31-60 Days", key: "thirtyone_to_sixty" },
        { label: "61-90 Days", key: "sixty_to_ninety" },
        { label: "Above 90 Days", key: "above_ninety" },
        { label: "Total", key: "total" },
    ];
    const pdfHeaders = [
      "Supplier",
      "Current",
      "1-30 Days",
      "31-60 Days",
      "61-90 Days",
      "Above 90 Days",
      "Total ",
    ]

    async function fetchSuppliers() {
        setShowLoader(true);
        setSuppliers([])
    
        const response = await getAllSuppliers(filterConfig);
        if (response.error) {
          TokenExpiry(response.error);
        } else {
            var supplier = response.data.data.map((data) => {
                var info = {};
                info.label = data.trade_name;
                info.value = data.id;
                return info;
            });
            setSuppliers([{label: "All Suppliers", value: ""}, ...supplier]);
        }
        setShowLoader(false);
      }
    
    async function fetchExpenseTypes() {
        setShowLoader(true);
        setExpenseTypes([])
    
        const response = await getAllExpenseType(filterConfig);
        if (response.error) {
          TokenExpiry(response.error);
        } else {
            var types = response.data.map((data) => {
                var info = {};
                info.label = data.name;
                info.value = data.id;
                return info;
            });
            setExpenseTypes([{label: "All Expense Types", value: ""}, ...types]);
        }
        setShowLoader(false);
    }

    async function fetchData() {
        setShowLoader(true);
        setAllData([])

        const response = await searchPayablesAgingReport(filterConfig);

        if (response.error) {
        } else {

            var allBills = response.data.payables_aging.map((bill) => {
                var info = bill;
                info.branch_id = bill.branch_id;
                info.total = numberFormat(bill.total);
                info.check_date = bill.cheque_date !== "0000-00-00" ? Moment(bill.cheque_date).format("MM-DD-YYYY") : "N/A";
                return info;
            });
            var pdfData = response.data.payables_aging.map((bill) => {
                return {
                customer : bill.supplier_name,
                current : bill.cur,
                one_to_thirthy : bill.one_to_thirthy,
                sixty_to_ninety : bill.sixty_to_ninety,
                thirtyone_to_sixty : bill.thirtyone_to_sixty,
                above_ninety : bill.above_ninety,
                total : bill.total,
                }
            });
            setPdfData(pdfData)

            var payables = response.data.summary? response.data.summary.total_payables : "0";
            setTotalPayables(payables);
            setAllData(allBills)
        }

        setShowLoader(false);
    }
    function splitData(row, header) {

        var split = row[header]?row[header].split(","):[];
         return (
            <Row>{
                split.map((data) => {
                    return (
                        <>
                            <label>{data}</label><br/>
                        </>
                    );
                })
            }
            </Row>
        )
    }

    function handleToCSV() {
        return (
        <CSVLink data={allData} headers={excelHeaders} filename={"PayablesAgingReport.csv"} style={{textDecoration: "none", color: "#ffffff"}}>            
            Export to CSV
        </CSVLink>
        );
    }

    React.useEffect(() => {
        fetchData();
    }, [filterConfig]);

    React.useEffect(() => {
        fetchSuppliers();
        fetchExpenseTypes();
    }, []);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EXPENSES REPORT"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-1">
                    <Col xs={6}>
                        <h1 className="page-title"> Payables Aging Report </h1>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                        <button className="add-btn text-center">{handleToCSV()}</button>
                        <ExportPDF name={"ReceivablesAgingReport"} data={pdfData} header={pdfHeaders}>
                        </ExportPDF>        
                    </Col>
                </Row>

                {/* <Row>
                    <Col className="d-flex justify-content-end mb-3">
                        <div className="justify-content-center align-items-center ">
                            <CSVLink
                                className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                                data={allData}
                                headers={excelHeaders}
                                target="_blank"
                                filename={`${getTodayDateISO()} Sales By Type`}
                            >
                                <span className="me-2">
                                    <img
                                        width={20}
                                        height={20}
                                        src={downloadIcon}
                                    ></img>
                                </span>
                                Download Excel
                            </CSVLink>
                        </div>
                    </Col>
                </Row> */}

                <div className="tab-content">
                    {/* filters */}
                    <div className="my-2 px-2 PO-filters d-flex">
                        <span className="me-1 align-middle mt-2 ps-label">
                            Filter By:
                        </span>
                        <Select
                            className="dropsearch-filter px-0 py-0 me-2"
                            classNamePrefix="react-select"
                            placeholder="Select Supplier"
                            styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: state.isSelected ? "white" : "#744aa4",
                                borderRadius: "7px",
                                border: "0px",
                                minHeight: "20px",
                                maxHeight: "35px",
                                textAlign: "left",
                            }),
                            input: (baseStyles, state) => ({
                                ...baseStyles,
                                color: state.isSelected ? "white" : "white",
                            }),
                            dropdownIndicator: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "white",
                            }),
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "white",
                            }),
                            placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "white",
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                textAlign: "left",
                            }),
                            }}
                            // value={selectedSupplier}
                            options={suppliers}
                            onChange={(e)=>{
                                setFilterConfig((prev) => {
                                    return { ...prev, "supplier_id": e.value };
                                });
                            }}
                        />
                        <Select
                            className="dropsearch-filter px-0 py-0 me-2"
                            classNamePrefix="react-select"
                            placeholder="Select Expense Type"
                            styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  backgroundColor: state.isSelected ? 'white' : '#744aa4',
                                  borderRadius: "7px",
                                  border: "0px",
                                  minHeight: "20px",
                                  maxHeight: "35px",
                                }),
                                input: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: state.isSelected? "white": "white",
                                    
                                  }),
                                  dropdownIndicator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                  }),
                                  singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                  }),
                                  placeholder: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                  }),
                                  
                              }}
                            // value={branch}
                            options={expenseTypes}
                            onChange={(e)=>{
                                setFilterConfig((prev) => {
                                    return { ...prev, "expense_id": e.value };
                                });
                            }}
                        />

                        {/* <span className="me-1 align-middle mt-2 ps-label">
                            From:
                        </span>
                        <DatePicker
                            selected={filterConfig.date_from}
                            name="date_from"
                            placeholderText={"Select Date"}
                            onChange={(date) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, date_from: date };
                                });
                            }}
                            fixedHeight
                            className="PI-date-btn me-3 form-control"
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                        />

                        <span className="me-1 align-middle mt-2 ps-label">
                            To:
                        </span>
                        <DatePicker
                            selected={filterConfig.date_to}
                            name="date_to"
                            placeholderText={"Select Date"}
                            onChange={(date) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, date_to: date };
                                });
                            }}
                            fixedHeight
                            className="PI-date-btn me-3 form-control"
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                        /> */}
                    </div>

                    <div className="my-2 px-2 PO-filters d-flex justify-content-center">

                        <span className="me-4 ml-4 align-middle ps-label">
                            Total Payables: {numberFormat(totalPayables)}
                        </span>
                    </div>

                    <div className="below">
                        {/* table */}
                        <Table
                            tableHeaders={[
                                // "-",
                                "SUPPLIER",
                                "CURRENT",
                                "1-30",
                                "31-60",
                                "61-90",
                                "ABOVE 90",
                                "TOTAL",
                            ]}
                            headerSelector={[
                                // "-",
                                "supplier_name",
                                "cur",
                                "one_to_thirthy",
                                "thirtyone_to_sixty",
                                "sixtyone_to_ninety",
                                "above_ninety",
                                "total",
                            ]}
                            tableData={allData}
                            currentAging={(row) => splitData(row, "cur")}
                            oneToThirty={(row) => splitData(row, "one_to_thirty")}
                            thirtyOneToSixty={(row) => splitData(row, "thirtyone_to_sixty")}
                            sixtyOneToNinety={(row) => splitData(row, "sixty_to_ninety")}
                            aboveNinetyAging={(row) => splitData(row, "above_ninety")}
                            // ActionBtn={(row) => ActionBtn(row, "open")}
                            // ViewBtn={(row) => PrintBtn(row)}
                            showLoader={showLoader}
                        />
                    </div>
                    <div className="mb-2" />
                </div>
            </div>
        </div>
    );
}

