import React, { useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import "react-autocomplete-input/dist/bundle.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

import {
  getTodayDateISO,
  numberFormat,
  toastStyle,
} from "../../Helpers/Utils/Common";
import Navbar from "../../Components/Navbar/Navbar";
import "../Purchases/PurchaseOrders/PurchaseOrders.css";
import "./Project.css";
import { Fragment } from "react";
import { useEffect } from "react";

import {
  createProject,
  updateProject,
  getProject,
  getProjectTypes,
} from "../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "../../Helpers/apiCalls/Manage/CustomerAPI";
import InputError from "../../Components/InputError/InputError";
import { validateProject } from "./../../Helpers/Validation/Project/ProjectValidation";
import trash from "../../Assets/Images/trash.png";
import { getDistributor } from "../../Helpers/apiCalls/Distributor/DistributorApi";

/**
 *  -- COMPONENT: FORM TO ADD OR EDIT FRANCHISEE SALES INVOICE
 */
function FormProject({ add, edit, renew }) {
  const navigate = useNavigate();

  const [inactive, setInactive] = useState(true);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  /**
   *  @po_id - param for add purchase invoice form
   *  @id - param for edit purchase invoice form
   */
  const { id } = useParams();
  const today = getTodayDateISO();

  // FRANCHISEE INVOICE DETAILS HANDLER
  const [projectDetails, setProjectDetails] = useState({
    name: "",
    project_date: "",
    start_date: "",
    renewal_date: "",
    contact_number: "",
    customer_id: "",
    contact_person: "",
    address: "",
    project_type: "",
    project_price: "0",
    vat_type: "vat_ex",
    subtotal: 0,
    vat_twelve: 0,
    vat_net: 0,
    withholding_tax: 0,
    grand_total: 0,
    one_time_fees_total: 0,
    recurring_cost_total: 0,
    distributor_id: "0",
  });

  // const [selectedYear, setSelectedYear] = useState();

  const [recurring, setRecurring] = useState([
    {
      description: "Server Fee",
      type: "yearly",
      suffix: "year",
      periods: "1",
      prices: "0",
      // year: selectedYear,
    },
    {
      description: "Support Fee",
      type: "yearly",
      suffix: "year",
      periods: "1",
      prices: "0",
      // year: selectedYear,
    },
  ]);

  const [oneTimeFees, setOneTimeFees] = useState([
    {
      description: "",
      amount: "",
    },
  ]);

  //ERROR HANDLING
  const [isError, setIsError] = useState({
    name: false,
    project_date: false,
    start_date: false,
    contact_number: false,
    customer_id: false,
    contact_person: false,
    address: false,
    project_type: false,
    prices: false,
    received_items_table: false,
  });

  // DATA HANDLERS
  const [customersData, setCustomersData] = useState([]);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);

  async function fetchDistributors() {
    const response = await getDistributor("");
    if (response.data) {
      const res = response.data.data.map((row) => {
        var info = row;
        info.label = row.name;
        info.value = row.id;
        return info;
      });
      setDistributors(res);
    }
  }

  async function fetchCustomer() {
    setCustomersData([]);
    const response = await getAllCustomer();

    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.data
    ) {
      let result = response.data.data.data.map((a) => {
        return {
          label: a.name,
          value: a.id,
          contact_number: a.contact_number,
          contact_person: a.contact_person,
          address: a.address,
          company: a.company,
          email: a.email,
        };
      });
      setCustomersData(result);
      setCustomerInfo(result);
    }
  }

  async function fetchProject() {
    const response = await getProject(id);
    if (response.data) {
      var data = response.data.data[0];
      const tempRecurring = data.recurring_cost?.map((row) => {
        var tempSuffix = "";
        if (row.type === "daily") {
          tempSuffix = "day/s";
        } else if (row.type === "weekly") {
          tempSuffix = "week/s";
        } else if (row.type === "monthly") {
          tempSuffix = "month/s";
        } else if (row.type === "yearly") {
          tempSuffix = "year/s";
        }
        return {
          ...row,
          suffix: tempSuffix,
        };
      });
      setRecurring(tempRecurring);

      var tempTotalOneTime = 0;
      data.one_time_fee?.forEach((row) => {
        tempTotalOneTime += parseFloat(row.amount);
      });
      setOneTimeFees(data.one_time_fee);
      setProjectDetails({
        ...data,
        subtotal:
          parseFloat(tempTotalOneTime) + parseFloat(data.recurring_cost_total),
        one_time_fees_total: tempTotalOneTime,
        recurring_cost_total: parseFloat(data.recurring_cost_total),
        grand_total: parseFloat(data.grand_total),
        project_type: data.project_types?.map(
          (item) => item.project_type_name_id
        ),
      });
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      });
    }
  }

  async function fetchAllProjectTypes() {
    setProjectTypes([]);
    const response = await getProjectTypes();
    if (response.data) {
      const res = response.data.data.map((row) => {
        return {
          value: row.id,
          label: row.name,
        };
      });
      setProjectTypes(res);
    }
  }

  //ADD FUNCTIONS
  async function handleCreateProject() {
    if (isSubmitClicked) {
      return;
    }

    if (validateProject(projectDetails, recurring, oneTimeFees, setIsError)) {
      setIsSubmitClicked(true);
      const response = await createProject(
        projectDetails,
        recurring,
        oneTimeFees
      );
      if (response.data) {
        if (response.data?.message === "Data already exists") {
          setIsSubmitClicked(false);
          toast.error("Data already exists", { style: toastStyle() });
        } else {
          setIsSubmitClicked(false);
          toast.success("Successfully created Project", {
            style: toastStyle(),
          });
          setTimeout(
            () => navigate("/project/print/" + response.data.project_id),
            1000
          );
        }
      } else {
        setIsSubmitClicked(false);
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        });
      }
    } else {
      toast.error("Please fill in all fields", {
        style: toastStyle(),
      });
    }
  }

  //Recurring Cost
  function handleRecurringChange(e, index) {
    const { name, value } = e.target;
    var temp = [...recurring];

    if (name === "type") {
      temp[index][name] = value;
      if (value === "daily") {
        temp[index].suffix = "day/s";
      } else if (value === "weekly") {
        temp[index].suffix = "week/s";
      } else if (value === "monthly") {
        temp[index].suffix = "month/s";
      } else if (value === "yearly") {
        temp[index].suffix = "year/s";
      }
    } else {
      temp[index][name] = value;
    }

    calculateTotal(temp);
    setRecurring(temp);
  }

  //Cost
  function handleCostChange(e, index) {
    const { name, value } = e.target;
    const newList = [...oneTimeFees];
    newList[index][name] = value;
    setOneTimeFees(newList);
    calculateOneTimeFeeTotal(newList);
  }

  const handleKeyPress = (e) => {
    const allowedChars = /[0-9.]/;
    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  };

  function handleDelRow(id) {
    const newList = [...recurring];
    newList.splice(id, 1);
    setRecurring(newList);
    calculateTotal(newList);
  }

  function handleDelRowCost(id) {
    const newList = [...oneTimeFees];
    newList.splice(id, 1);
    setOneTimeFees(newList);
    calculateOneTimeFeeTotal(newList);
  }

  function handleAddNewRow() {
    const newItem = {
      description: "",
      type: "yearly",
      suffix: "year",
      periods: "1",
      prices: "",
      year: "",
    };
    setRecurring((oldItems) => [...oldItems, newItem]);
  }

  function handleAddNewRowCost() {
    const newItem = {
      description: "",
      account_type: "",
      amount: "",
    };
    setOneTimeFees((oldItems) => [...oldItems, newItem]);
  }
  //EDIT FUNCTIONS
  async function handleUpdatePI() {
    if (validateProject(projectDetails, recurring, setIsError)) {
      const response = await updateProject(
        projectDetails,
        recurring,
        oneTimeFees
      );
      if (response.data) {
        toast.success("Successfully updated Project", {
          style: toastStyle(),
        });
        setTimeout(() => navigate(-1), 1000);
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        });
      }
    } else {
      toast.error("Please fill in all fields", {
        style: toastStyle(),
      });
    }
  }

  //HANDLES
  const handleSubmit = () => {
    if (add) handleCreateProject();
    else if (edit) handleUpdatePI();
    else if (renew) handleUpdatePI();
  };

  const handleChange = (e, field) => {
    if (field === "customer_id") {
      customerInfo
        .filter((info) => info.value === e)
        .map((data) => {
          setProjectDetails((prevState) => ({
            ...prevState,
            customer_id: e,
            email: data.email,
            contact_number: data.contact_number,
            contact_person: data.contact_person,
            address: data.address,
            company: data.company,
          }));
        });
    } else {
      const { name, value } = e.target;
      setProjectDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleVatChange = (e, isSelect) => {
    const { name, value } = e.target;
    if (name === "customer_id") {
      customerInfo
        .filter((info) => info.value === value)
        .map((data) => {
          setProjectDetails((prevState) => ({
            ...prevState,
            customer_id: value,
            email: data.email,
            contact_number: data.contact_number,
            contact_person: data.contact_person,
            address: data.address,
            company: data.company,
          }));
        });
    } else {
      if (value === "vat_in") {
        const grossTotal =
          parseFloat(projectDetails.recurring_cost_total) +
          parseFloat(projectDetails.one_time_fees_total);
        var tempVatNet = parseFloat(grossTotal / 1.12);
        var tempWth = parseFloat(tempVatNet * 0.02);
        var temp12 = parseFloat(grossTotal - tempVatNet);

        setProjectDetails((prev) => ({
          ...prev,
          [name]: value,
          subtotal: grossTotal,
          vat_net: tempVatNet,
          vat_twelve: temp12,
          withholding_tax: tempWth,
          grand_total: parseFloat(grossTotal - tempWth),
        }));
      } else if (value === "vat_ex") {
        setProjectDetails((prev) => ({
          ...prev,
          [name]: value,
          subtotal:
            parseFloat(prev.one_time_fees_total) +
            parseFloat(prev.recurring_cost_total),
          vat_net: 0,
          vat_twelve: 0,
          withholding_tax: 0,
          grand_total:
            parseFloat(prev.one_time_fees_total) +
            parseFloat(prev.recurring_cost_total),
        }));
      }
    }
  };

  //USE EFFECTS
  useEffect(() => {
    if (edit || renew) {
      fetchProject();
    }
    fetchCustomer();
    fetchDistributors();
    fetchAllProjectTypes();
  }, []);

  function calculateTotal(recurringData) {
    var temp_yearly = 0;
    var temp_monthly = 0;
    var temp_weekly = 0;
    var temp_daily = 0;

    recurringData
      .filter((data) => data.suffix?.includes("year"))
      .forEach((info) => {
        temp_yearly += Number(info.prices) * Number(info.periods);
      });
    recurringData
      .filter((data) => data.suffix?.includes("month"))
      .forEach((info) => {
        temp_monthly += Number(info.prices) * Number(info.periods);
      });
    recurringData
      .filter((data) => data.suffix?.includes("week"))
      .forEach((info) => {
        temp_weekly += Number(info.prices) * Number(info.periods);
      });
    recurringData
      .filter((data) => data.suffix?.includes("day"))
      .forEach((info) => {
        temp_daily += Number(info.prices) * Number(info.periods);
      });

    const tempTotal =
      parseFloat(temp_yearly) +
      parseFloat(temp_monthly) +
      parseFloat(temp_weekly) +
      parseFloat(temp_daily);

    setProjectDetails((prev) => ({
      ...prev,
      vat_type: "vat_ex",
      subtotal: parseFloat(prev.one_time_fees_total) + tempTotal,
      vat_net: 0,
      vat_twelve: 0,
      withholding_tax: 0,
      recurring_cost_total: tempTotal,
      grand_total: parseFloat(prev.one_time_fees_total) + tempTotal,
    }));
  }

  function calculateOneTimeFeeTotal(newList) {
    var temp_total = 0;
    newList.forEach((data) => {
      temp_total += parseFloat(data.amount);
    });
    const tempTotal = parseFloat(temp_total);
    setProjectDetails((prev) => ({
      ...prev,
      vat_type: "vat_ex",
      subtotal: tempTotal + parseFloat(prev.recurring_cost_total),
      vat_net: 0,
      vat_twelve: 0,
      withholding_tax: 0,
      one_time_fees_total: tempTotal,
      grand_total: tempTotal + parseFloat(prev.recurring_cost_total),
    }));
  }

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`container ${inactive ? "inactive" : "active"}`}>
        {/* header */}
        <div className="d-flex justify-content-between align-items-center my-3 pb-4">
          <h1 className="page-title mb-0">{add ? "ADD " : "EDIT "}PROJECT</h1>
        </div>

        {/* content */}
        <div className="edit-form">
          {/* FRANCHISEE SALES INVOICE DETAILS */}
          <Fragment>
            <Row className="pt-3 mb-2">
              <Col xs={5}>
                <span className="edit-label">
                  Project Name
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
              <Col xs={3}>
                <span className="edit-label">
                  Contract Date
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
              <Col>
                <span className="edit-label">
                  Start Date (deployment)
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                <Form.Control
                  type="text"
                  name="name"
                  value={projectDetails.name}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleChange(e, "name")}
                  required
                  disabled={renew}
                />
                <InputError
                  isValid={isError.name}
                  message={"Project is required"}
                />
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="date"
                  name="project_date"
                  className="nc-modal-custom-text"
                  value={projectDetails.project_date}
                  defaultValue={today}
                  onChange={(e) => {
                    // const selectedDate = new Date(e.target.value);
                    // setSelectedYear(selectedDate.getFullYear());
                    setProjectDetails((prev) => ({
                      ...prev,
                      project_date: e.target.value,
                    }));
                  }}
                  disabled={renew}
                />
                <InputError
                  isValid={isError.project_date}
                  message={"Contract date is required"}
                />
              </Col>
              <Col>
                <Form.Control
                  type="date"
                  name="start_date"
                  className="nc-modal-custom-text"
                  value={projectDetails.start_date}
                  onChange={(e) => handleChange(e, "start_date")}
                  disabled={renew}
                />
                <InputError
                  isValid={isError.start_date}
                  message={"Start date is required"}
                />
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">
                  Customer Name
                  <label className="badge-required">{` *`}</label>
                </span>
              </Col>
              <Col>
                <span className="edit-label">Address</span>
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                {/* <Form.Select
                  type="select"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select Customer..."
                  name="customer_id"
                  value={projectDetails.customer_id}
                  onChange={(e) => handleChange(e, false)}
                  
                >
                  <option value="">Select a Customer...</option>
                  {customersData.map((data) => {
                    return (
                      <option key={data.value} value={data.value}>
                        {data.label}
                      </option>
                    );
                  })}
                </Form.Select> */}
                <Select
                  className="w-100"
                  options={customersData}
                  dropdownStyle={{ fontFamily: "var(--primary-font-regular)" }}
                  value={projectDetails.customer_id}
                  // onChange={(e) => {
                  //   setProjectDetails((prevState) => ({
                  //     ...prevState,
                  //     customer_id: e,
                  //   }));
                  // }}
                  onChange={(e) => handleChange(e, "customer_id")}
                  disabled={renew}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                />
                <InputError
                  isValid={isError.customer_id}
                  message={"Customer is required"}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="address"
                  className="nc-modal-custom-text"
                  value={projectDetails.address}
                  onChange={(e) => handleChange(e, "address")}
                  disabled={renew}
                />
                <InputError
                  isValid={isError.address}
                  message={"Address is required"}
                />
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">
                  Company
                  {/* <label className="badge-required">{` *`}</label> */}
                </span>
              </Col>
              <Col>
                <span className="edit-label">Contact Person</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  name="company"
                  value={projectDetails.company}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleChange(e, "company")}
                  disabled={renew}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="contact_person"
                  value={projectDetails.contact_person}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleChange(e, "contact_person")}
                  disabled={renew}
                />
                <InputError
                  isValid={isError.contact_person}
                  message={"Contact person is required"}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={4}>
                <span className="edit-label">Select Distributor</span>
                <Select
                  className="w-100"
                  options={[
                    { value: "0", label: "No distributor" },
                    ...distributors,
                  ]}
                  dropdownStyle={{ fontFamily: "var(--primary-font-regular)" }}
                  value={projectDetails.distributor_id}
                  onChange={(e) => {
                    setProjectDetails((prevState) => ({
                      ...prevState,
                      distributor_id: e,
                    }));
                  }}
                  disabled={renew}
                />
              </Col>
              <Col xs={4}>
                <span className="edit-label">Payment Structure</span>
                {/* <Form.Select
                  onChange={(e) =>
                    setProjectDetails((prevState) => ({
                      ...prevState,
                      payment_structure: e.target.value,
                    }))
                  }
                  className="w-90 ms-0"
                >
                  <option value="One-Time Fee">One-Time Fee</option>
                  <option value="Subsciption Based">Subsciption Based</option>
                </Form.Select> */}
                <Select
                  className="w-100"
                  options={[
                    { value: "One-Time Fee", label: "One-Time Fee" },
                    { value: "Subsciption Based", label: "Subsciption Based" },
                  ]}
                  dropdownStyle={{ fontFamily: "var(--primary-font-regular)" }}
                  value={projectDetails.payment_structure}
                  onChange={(e) => {
                    setProjectDetails((prevState) => ({
                      ...prevState,
                      payment_structure: e,
                    }));
                  }}
                  disabled={renew}
                />
              </Col>
              <Col xs={4}>
                <Row>
                  <span className="edit-label">Project Type</span>
                </Row>
                <Row>
                  <Select
                    className="w-100"
                    options={projectTypes}
                    mode="multiple"
                    dropdownStyle={{
                      fontFamily: "var(--primary-font-regular)",
                    }}
                    onChange={(e) => {
                      setProjectDetails((prevState) => ({
                        ...prevState,
                        project_type: e,
                      }));
                    }}
                    value={projectDetails.project_type}
                    disabled={renew}
                  />
                </Row>
                {/* <Form.Select
                  onChange={(e) =>
                    // setProjectDetails((prevState) => ({
                    //   ...prevState,
                    //   project_type: e.target.value,
                    // }))
                  }
                  className="w-90 ms-0"
                >
                  {
                    systemProjectTypes.map((row) => (
                      <option value={row.value}>{row.label}</option>
                    ))
                  }
                </Form.Select> */}
              </Col>
              {/* <Col>
                <Row className="mt-2">
                  <span className="edit-label">Select Billing Date</span>
                  <ReactDatePicker className="form-control" 
                  selected={billingDate} onChange={(value) => setBillingDate(value)}
                  />
                </Row>
              </Col> */}
            </Row>
          </Fragment>
          {renew && (
            <Row className="mt-3">
              <Row>
                <span className="edit-label">Renewal Date</span>
              </Row>
              <Col xs={4}>
                <Form.Control
                  type="date"
                  name="renewal_date"
                  className="nc-modal-custom-text"
                  value={projectDetails.renewal_date}
                  defaultValue={today}
                  onChange={(e) => handleChange(e, "renewal_date")}
                />
                <InputError
                  isValid={isError.renewal_date}
                  message={"Renewal date is required"}
                />
              </Col>
            </Row>
          )}
          <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">ONE TIME FEE</span>
            <div className="edit-purchased-items">
              {oneTimeFees.length !== 0 ? (
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th className="color-gray">Descriptions</th>
                        {/* <th className="color-gray">Type</th> */}
                        <th className="color-gray">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {oneTimeFees.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <Form.Control
                                type="text"
                                name="description"
                                defaultValue={item.description}
                                // value={item.descriptions}
                                onChange={(e) => handleCostChange(e, index)}
                              />
                              <InputError
                                isValid={isError.description}
                                message={"Cost is required"}
                              />
                            </td>

                            <td>
                              <Form.Control
                                type="number"
                                name="amount"
                                defaultValue={item.amount}
                                // value={item.amount}
                                onChange={(e) => handleCostChange(e, index)}
                                onKeyPress={(e) => handleKeyPress(e)}
                              />
                              <InputError
                                isValid={isError.amount}
                                message={"Cost is required"}
                              />
                            </td>
                            <td className="text-center">
                              <img
                                src={trash}
                                onClick={() => handleDelRowCost(index)}
                                className="cursor-pointer"
                                alt=""
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <InputError
                    isValid={isError.item_info}
                    message={"Please make sure all fields are filled"}
                  />
                </>
              ) : (
                <div className="entries-not-found">
                  There is no data recorded yet.
                </div>
              )}
            </div>
          </Row>
          {/* ADD ITEM BUTTON */}
          <Row className="pt-3 PO-add-item">
            <Button type="button" onClick={() => handleAddNewRowCost()}>
              Add One Time Fee
            </Button>
          </Row>
          <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">Recurring Fee</span>
            <div className="edit-purchased-items">
              {recurring.length !== 0 ? (
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th className="color-gray">Descriptions</th>
                        <th className="color-gray">Type</th>
                        <th className="color-gray">Periods</th>
                        <th className="color-gray">Amount</th>
                        {/* <th className="color-gray">Year</th> */}
                        <th className="color-gray">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recurring.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <Form.Control
                                type="text"
                                name="description"
                                value={item.description}
                                onChange={(e) =>
                                  handleRecurringChange(e, index)
                                }
                              />
                            </td>
                            <td>
                              <Form.Select
                                name="type"
                                value={item.type}
                                onChange={(e) => {
                                  handleRecurringChange(e, index);
                                }}
                              >
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                              </Form.Select>
                            </td>
                            <td>
                              <Row>
                                <Col>
                                  <Form.Control
                                    type="number"
                                    name="periods"
                                    value={item.periods}
                                    onChange={(e) => {
                                      if (!renew) {
                                        handleRecurringChange(e, index);
                                      }
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    type="text"
                                    name="suffix"
                                    disabled
                                    value={item.suffix ?? ""}
                                  />
                                </Col>
                              </Row>
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="prices"
                                defaultValue={item.price}
                                // value={item.price}
                                onChange={(e) =>
                                  handleRecurringChange(e, index)
                                }
                                onKeyPress={(e) => handleKeyPress(e)}
                              />
                              <InputError
                                isValid={isError.prices}
                                message={"Cost is required"}
                              />
                            </td>
                            {/* <td>
                              <Form.Control
                                type="text"
                                name="year"
                                value={item.year}
                                onChange={(e) =>
                                  handleRecurringChange(e, index)
                                }
                              />
                            </td> */}
                            <td className="text-center">
                              <img
                                src={trash}
                                onClick={() => handleDelRow(index)}
                                className="cursor-pointer"
                                alt=""
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <InputError
                    isValid={isError.item_info}
                    message={"Please make sure all fields are filled"}
                  />
                </>
              ) : (
                <div className="entries-not-found">
                  There is no Recurring Fee recorded yet.
                </div>
              )}
              <InputError
                isValid={isError.items}
                message={"Please add at least 1 Recurring"}
              />
            </div>
          </Row>

          {/* ADD ITEM BUTTON */}
          <Row className="pt-3 PO-add-item">
            <Button type="button" onClick={() => handleAddNewRow()}>
              Add Recurring Fee
            </Button>
          </Row>
          <Row className="align-right pt-3">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray">One Time Fees</span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle">PHP</span>
            </Col>
            <Col xs={3} className="text-end">
              <Form.Control
                // type="number"
                name="one_time_fees_total"
                min={0}
                step="0.01"
                value={numberFormat(projectDetails.one_time_fees_total) || 0}
                className="align-middle nc-modal-custom-text text-end"
                // onChange={(e) => handleChange(e)}
                disabled
              />
            </Col>
          </Row>
          <Row className="align-right pt-3">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray">Recurring Fees</span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle">PHP</span>
            </Col>
            <Col xs={3} className="text-end">
              <Form.Control
                // type="number"
                name="recurring_cost_total"
                min={0}
                step="0.01"
                value={numberFormat(projectDetails.recurring_cost_total) || 0}
                className="align-middle nc-modal-custom-text text-end"
                disabled
                // onChange={(e) => handleChange(e)}
              />
            </Col>
          </Row>
          <Row className="align-right pt-3">
            <Col className="text-end">
              <Form.Check
                inline
                label="VAT Ex"
                name="vat_type"
                type="radio"
                value="vat_ex"
                checked={projectDetails.vat_type === "vat_ex"}
                onClick={(e) => {
                  handleVatChange(e);
                }}
                disabled={renew}
              />
              <Form.Check
                inline
                label="VAT In"
                name="vat_type"
                type="radio"
                value="vat_in"
                checked={projectDetails.vat_type === "vat_in"}
                onClick={(e) => {
                  handleVatChange(e);
                }}
                disabled={renew}
              />
            </Col>
          </Row>

          {projectDetails.vat_type === "vat_in" && (
            <>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">Subtotal</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={3} className="text-end">
                  <span className="edit-label align-middle vat-total-text text-end">
                    {numberFormat(projectDetails.subtotal)}
                  </span>
                </Col>
              </Row>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">12% VAT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={3} className="text-end">
                  <span className="edit-label align-middle vat-total-text text-end">
                    {numberFormat(projectDetails.vat_twelve)}
                  </span>
                </Col>
              </Row>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">Net of VAT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={3} className="text-end">
                  <span className="edit-label align-middle vat-total-text text-end">
                    {numberFormat(projectDetails.vat_net)}
                  </span>
                </Col>
              </Row>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">WHT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={3} className="text-end">
                  <span className="edit-label align-middle vat-total-text text-end">
                    {numberFormat(projectDetails.withholding_tax)}
                  </span>
                </Col>
              </Row>
            </>
          )}
          <Row className="align-right py-5">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray grand-total-text">
                Grand Total
              </span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle grand-total-text">
                PHP
              </span>
            </Col>
            <Col xs={3} className="text-end">
              <span className="edit-label align-middle grand-total-text text-end">
                {numberFormat(projectDetails.grand_total)}
              </span>
            </Col>
          </Row>
          {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
          <div className="d-flex justify-content-end pt-5 pb-3">
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

FormProject.defaultProps = {
  add: false,
  edit: false,
};

export default FormProject;
