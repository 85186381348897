import React, { forwardRef, useState, useEffect } from "react";
import { Col, Form, Row, Modal, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/TableTemplate/DataTable";
import DeleteModal from "../../Components/Modals/DeleteModal";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import {
  getTodayDate,
  formatAmount,
  getType, toastStyle, selectDropdownStyle, numberFormatInt
} from "../../Helpers/Utils/Common";
import { toast } from "react-hot-toast";
import AddBillingPayment from "./AddBillingPayment";
import { searchProject } from "../../Helpers/apiCalls/Manage/Projects";
import { getSubscriptionBillings, deleteSubscriptionBilling } from "../../Helpers/apiCalls/Subscription/SubscriptionBilling";

export default function SubscriptionBillings () {
  let navigate = useNavigate();
  const userType = getType();
  const [inactive, setInactive] = useState(true);
  const [distributors, setDistributors] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [billingDate, setBillingDate] = useState(new Date());
  const [selectedRow, setSelectedRow] = useState({});
  const [addBilling, setAddBilling] = useState(false);
  const [filterConfig, setFilterConfig] = useState({status: "open_bill"});

  function handleFilterChange(e) {
    const { name, value } = e.target;
    setFilterConfig((prev) => {
      return { ...prev, [name]: value };
    });
  }


  async function fetchData() {
    setShowLoader(true);
    const response = await getSubscriptionBillings(filterConfig)
    if(response.data) {
      const res = response.data.data.map((row) => {
        var info = row;
        info.billing_date = moment(row.billing_date).format("MMM DD, YYYY");
        info.grand_total = formatAmount(Number(row.grand_total));
        var paidAmount = row.paid_amount?Number(row.paid_amount):0;
        info.paid_amount = formatAmount(paidAmount);
        info.balance = formatAmount(numberFormatInt(row.grand_total)-paidAmount);
        return info;
      })
      setTableData(res);
    } else {
      setTableData([]);
    }
    setShowLoader(false);
  }

  async function handleDelete() {
    const response = await deleteSubscriptionBilling(selectedRow.id);
    
    if (response.data) {
      toast.success(response.data.response, {style: toastStyle()})
      setShowDeleteModal(false);
      fetchData();
    } else {
      toast.error(response.error.data.messages.response, {style: toastStyle()})
    }
  }

  function handleSelectChange(e, row) {
    setSelectedRow(row);
    if (e.target.value === "edit") {
        navigate("/subscription_billings/billing_statement", {state: {formPurpose: 'edit', subscription_id: row.id, billingDate: billingDate}});
    } else if (e.target.value==="add-payment") {
      setAddBilling(true);
    } else if (e.target.value==="delete") {
     setShowDeleteModal(true)
    }
  }

  function ActionBtn(row) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action"
        onChange={(e) =>
          handleSelectChange(e, row)
        }
        value={""}
      >
        <option value="" hidden selected>
          Select
        </option>
        {
          userType==="admin"&&row.status==="open_bill" && (
            <option value="edit" className="color-options">
              Edit
            </option>
          )
        }
  
       {userType === "admin" ? (
          <option value="add-payment" className="color-options">
            Add Payment
          </option>
        ) : null}
        {userType === "admin" && (
          <option value="delete" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

  function ViewBtn(row) {
    return (
      <button
        type="button"
        className="btn btn-sm view-btn-table"
        onClick={() => {
          // 
          navigate("view/"+row.id)
        }}
      >
        View
      </button>
    );
  }

  useEffect(() => {
      async function fetchProjects () {
        const response = await searchProject('')
        if (response.data) {
            const res = response.data.data.map((row) => {
                var info = row;
                info.label = row.name;
                info.value = row.id;
                return info;
            });
            setProjects(res);
        }
      }

    fetchProjects()
  }, []);

  useEffect(() => {
    fetchData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">SUBSCRIPTION BILLINGS REGISTER</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <input
              type="search"
              name="project_name"
              placeholder="Search project..."
              value={filterConfig.project_name}
              onChange={(e) => handleFilterChange(e)}
              className="search-bar"
            />
            <button
              className="add-btn"
              onClick={() => setShowSelect(true)}
            >
              Add
            </button>
          </Col>
        </Row>

        <Tabs activeKey={filterConfig.status} onSelect={(tab) => setFilterConfig((prev) => ({...prev, status: tab}))}>
            <Tab eventKey={"open_bill"} title="Open Bills">
            <div className="tab-content">
              <div className="my-2 ms-2 PO-filters PI-filters d-flex">
                <span className="me-3 align-middle mt-2">FILTER BY:</span>

                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="All Projects"
                  styles={selectDropdownStyle}
                  options={projects}
                  onChange={(e) => { setFilterConfig((prev) => {
                      return { ...prev, "project_id": e.value };
                  });}}
                />
                
              </div>
            </div>
              <Table
                tableHeaders={[
                  "-",
                  "PROJECT NAME",
                  "TOTAL",
                  // "PAID AMOUNT",
                  "BALANCE",
                  "BILLING DATE",
                  "DEADLINE",
                  "ACTIONS",
                ]}
                headerSelector={[
                  "-",
                  "project_name",
                  "grand_total",
                  // "paid_amount",
                  "balance",
                  "billing_date",
                  "due_date",
                ]}
                tableData={tableData}
                ViewBtn={(row) => ViewBtn(row)}
                ActionBtn={(row) => ActionBtn(row)}
                showLoader={showLoader}
                withActionData={false}
              />
            </Tab>
            <Tab eventKey={"closed_bill"} title="Closed Bills">
            <div className="tab-content">
              <div className="my-2 ms-2 PO-filters PI-filters d-flex">
                <span className="me-3 align-middle mt-2">FILTER BY:</span>

                <Select
                  className="dropsearch-filter px-0 py-0 me-2"
                  classNamePrefix="react-select"
                  placeholder="All Projects"
                  styles={selectDropdownStyle}
                  options={projects}
                  onChange={(e) => { setFilterConfig((prev) => {
                      return { ...prev, "project_id": e.value };
                  });}}
                />
                
              </div>
            </div>
              <Table
                tableHeaders={[
                  "-",
                  "PROJECT NAME",
                  "TOTAL",
                  "PAID AMOUNT",
                  "BALANCE",
                  "BILLING DATE",
                  "ACTIONS",
                ]}
                headerSelector={[
                  "-",
                  "project_name",
                  "grand_total",
                  "paid_amount",
                  "balance",
                  "billing_date",
                ]}
                tableData={tableData}
                ViewBtn={(row) => ViewBtn(row)}
                ActionBtn={(row) => ActionBtn(row)}
                showLoader={showLoader}
                withActionData={false}
              />
            </Tab>
          </Tabs>
        
        <div className="mb-2" />
      </div>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        text="subscription billing"
        onDelete={handleDelete}
      />

      <AddBillingPayment show={addBilling} hide={()=>setAddBilling(false)} subscriptionBillingData={selectedRow}/>

      <Modal size="md" show={showSelect} onHide={() => setShowSelect(false)}>
        <Modal.Body className="p-5">
            <Row>
            <span className="edit-label">Select Project</span>
              <Form.Select onChange={(e) => {setSelectedProject(e.target.value)}} className="w-90 ms-2">
                <option value={""}>---</option>
                {
                  projects.map((row) => (
                    <option value={row.id}>{row.name}</option>
                  ))
                }
              </Form.Select>
            </Row>
            <Row className="mt-3">
            <span className="edit-label">Select Billing Date</span>
              <ReactDatePicker className="form-control" selected={billingDate} onChange={(value) => setBillingDate(value)}/>
            </Row>
              <div className="col-sm-12 mt-3 d-flex justify-content-end">
                <button className="button-secondary me-3" onClick={() => setShowSelect(false)}>
                  Cancel
                </button>
                <button disabled={selectedProject===""} 
                  className={`button-primary ${selectedProject&&billingDate ? '' : 'disabled'}`}
                  onClick={() => {navigate("/subscription_billings/billing_statement", {state: {formPurpose: 'add', project_id: selectedProject, billingDate: billingDate}});}}>
                  Generate
                </button>
            </div>
        </Modal.Body>
      </Modal>
    
    </div>
  );
}
