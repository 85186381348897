import React, { useState } from "react";
import {
  Col,
  Row,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import {
  numberFormat,
  TokenExpiry,
  selectDropdownStyle   
} from "../../../Helpers/Utils/Common";
import { searchProject } from "./../../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "./../../../Helpers/apiCalls/Manage/CustomerAPI";
import { searchReceivablesAgingReport } from "./../../../Helpers/apiCalls/SalesReport/SalesReportApi";
import ReceivablesAgingBreakdownModal from "../../../Components/Modals/ReceivablesAgingBreakdownModal";
import { CSVLink } from "react-csv";
import ExportPDF from "../../../Components/Export/ExportPdf";

export default function ReceivablesAgingReport() {
  const navigate = useNavigate();
  const [inactive, setInactive] = useState(true);
  const [filterConfig, setFilterConfig] = useState({
    payment_status: "paid",
  });
  const[pdfData, setPdfData] = useState([])
  const [
    showReceivablesAgingBreakdownModal,
    setShowReceivablesAgingBreakdownModal,
  ] = useState(false);
  const handleCloseReceivablesAgingBreakdownModal = () =>
    setShowReceivablesAgingBreakdownModal(false);

  const [showLoader, setShowLoader] = useState(false);
  const [allData, setAllData] = useState([]);
  const [receivablesAgingBreakdownData, setReceivablesAgingBreakdownData] =
    useState([]);
  const [totalReceivables, setTotalReceivables] = useState([]);
  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const excelHeaders = [
    { label: "Customer", key: "customer_name" },
    { label: "Current", key: "cur" },
    { label: "1-30 Days", key: "one_to_thirthy" },
    { label: "31-60 Days", key: "thirtyone_to_sixty" },
    { label: "61-90 Days", key: "sixty_to_ninety" },
    { label: "Above 90 Days", key: "above_ninety" },
    { label: "Total", key: "total" },
  ];

  const pdfHeaders = [
      "Customer",
      "Current",
      "1-30 Days",
      "31-60 Days",
      "61-90 Days",
      "Above 90 Days",
      "Total ",
  ]

  async function fetchCustomers() {
    setShowLoader(true);
    setCustomers([]);

    const response = await getAllCustomer(filterConfig);
    if (response.error) {
      TokenExpiry(response.error);
    } else {
      var projects = response.data.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setCustomers([{ label: "All Customers", value: "" }, ...projects]);
    }
    setShowLoader(false);
  }

  async function fetchProjects() {
    setShowLoader(true);
    setProjects([]);

    const response = await searchProject(filterConfig);
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error.data.status);
      }
    } else {
      var projects = response.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setProjects([{ label: "All Projects", value: "" }, ...projects]);
    }
    setShowLoader(false);
  }

  async function handleShowReceivablesAgingBreakdownModal(row, header) {
    var split = row[header] ? row[header].split(",") : [];
    let breakdown = [];
    let aging_name = {
      cur: "CURRENT",
      one_to_thirty: "ONE TO THIRTY DAYS",
      thirtyone_to_sixty: "THIRTY ONE TO SIXTY",
      sixtyone_to_ninety: "SIXTY ONE TO NINETY",
      above_ninety: "ABOVE NINETY",
    };
    for (let i = 0; i < split.length; i++) {
      var data = {
        invoice_no: parseFloat(split[i].match(/INV\. ([^()]+)-\(\d+\.\d+\)/)?.[1]).trim(),
        amount: parseFloat(split[i].match(/\(([\d.]+)\)/)?.[1]).trim(),
        aging: aging_name[header],
        name: row.customer_name,
      };
      breakdown.push(data);
    }

    setReceivablesAgingBreakdownData(breakdown);
    setShowReceivablesAgingBreakdownModal(true);
  }

  async function fetchData() {
    setShowLoader(true);
    setAllData([]);

    const response = await searchReceivablesAgingReport(filterConfig);
    if (response.error) {
      setAllData([]);
    } else {
      var allBills = response.data.receivables_aging.map((bill) => {
        var info = bill;
        info.id = bill.customer_id;
        info.total = numberFormat(bill.total);
        return info;
      });
      var pdfData = response.data.receivables_aging.map((bill) => {
        return {
          customer : bill.customer_name,
          current : bill.cur,
          one_to_thirthy : bill.one_to_thirthy,
          sixty_to_ninety : bill.sixty_to_ninety,
          thirtyone_to_sixty : bill.thirtyone_to_sixty,
          above_ninety : bill.above_ninety,
          total : bill.total,
        }
      });
      setPdfData(pdfData)
      var receivables = response.data.summary
        ? response.data.summary.total_receivables
        : "0";
      setTotalReceivables(receivables);
      setAllData(allBills);
    }

    setShowLoader(false);
  }

  function PrintBtn(row) {
    return (
      <button
        name="action"
        className="btn btn-sm view-btn-table"
        id={row.id}
        onClick={() => handlePrint(row)}
      >
        View
      </button>
    );
  }

  function getData(row, header) {
    var split = row[header] ? [row[header]] : [];
    return (
      <Row>
        {split.map((data) => {
          return (
            <>
              <label>{data}</label>
              <br />
            </>
          );
        })}
      </Row>
    );
  }

  function sumData(row, header) {
    var split = row[header] ? row[header].split(",") : [];
    let totalExpense = 0;
    return (
      <ButtonGroup vertical>
        {split.map((data) => {
          totalExpense += parseFloat((data.match(/\(([\d.]+)\)/)?.[1]).trim());
        })}
        <>
          <Button
            className=""
            variant="outline-none"
            size="sm"
            onClick={() =>
              handleShowReceivablesAgingBreakdownModal(row, header)
            }
          >
            {numberFormat(totalExpense.toFixed(2))}
          </Button>
        </>
      </ButtonGroup>
    );
  }

  function handlePrint(row) {
    navigate("/receivablesagingreport/print/" + row.id, {state: {reportDetails: row}});
  }

  function handleToCSV() {
    return (
      <CSVLink data={allData} headers={excelHeaders} filename={"ReceivableAgingReport.csv"} style={{textDecoration: "none", color: "#ffffff"}}>            
        Export to CSV
      </CSVLink>
    );
  }

  React.useEffect(() => {
    fetchData();
  }, [filterConfig]);

  React.useEffect(() => {
    fetchCustomers();
    fetchProjects();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES REPORT"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> Receivables Aging Report </h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <button className="add-btn text-center">{handleToCSV()}</button>
            <ExportPDF name={"ReceivablesAgingReport"} data={pdfData} header={pdfHeaders}>
            </ExportPDF>
          </Col>
        </Row>

        <div className="tab-content">
          {/* filters */}
          <div className="my-2 px-2 PO-filters d-flex">
            <span className="me-1 align-middle mt-2 ps-label">Filter By:</span>
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Customers"
              styles={selectDropdownStyle}
              // value={branch}
              options={customers}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, customer_id: e.value };
                });
              }}
            />
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Project"
              styles={selectDropdownStyle}
              // value={branch}
              options={projects}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, project_id: e.value };
                });
              }}
            />
          </div>

          <div className="my-2 px-2 PO-filters d-flex justify-content-end">
            <span className="me-4 ml-4 align-middle ps-label">
              Total Receivables: {numberFormat(totalReceivables)}
            </span>
          </div>

          <div className="below">
            {/* table */}
            <Table
              tableHeaders={[
                // "-",
                "CUSTOMER",
                "CURRENT",
                "1-30 DAYS",
                "31-60 DAYS",
                "61-90 DAYS",
                "ABOVE 90 DAYS",
                "TOTAL",
              ]}
              headerSelector={[
                // "-",
                "customer_name",
                "cur",
                "one_to_thirty",
                "thirtyone_to_sixty",
                "sixtyone_to_ninety",
                "above_ninety",
                "total",
              ]}
              tableData={allData}
              currentAging={(row) => sumData(row, "cur")}
              oneToThirty={(row) => sumData(row, "one_to_thirty")}
              thirtyOneToSixty={(row) => sumData(row, "thirtyone_to_sixty")}
              sixtyOneToNinety={(row) => sumData(row, "sixty_to_ninety")}
              aboveNinetyAging={(row) => sumData(row, "above_ninety")}
              totalAging={(row) => getData(row, "total")}
              ViewBtn={(row) => PrintBtn(row)}
              showLoader={showLoader}
            />
          </div>
          <div className="mb-2" />
        </div>
      </div>
      <ReceivablesAgingBreakdownModal
        show={showReceivablesAgingBreakdownModal}
        onHide={handleCloseReceivablesAgingBreakdownModal}
        data={receivablesAgingBreakdownData}
      ></ReceivablesAgingBreakdownModal>
    </div>
  );
}

