import React, { useState, useEffect } from "react";
import { Col, Row, Button, ButtonGroup } from "react-bootstrap";
import { DatePicker } from "antd";
import Navbar from "../../Components/Navbar/Navbar";
import Select from "react-select";
import {
  TokenExpiry,
  numberFormat,
  selectDropdownStyle,
} from "../../Helpers/Utils/Common";
import { CSVLink } from "react-csv";

// css
import "./Project.css";
import "../Purchases/PurchaseOrders/PurchaseOrders.css";
import {
  searchProject,
  searchProjectSales,
} from "./../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "./../../Helpers/apiCalls/Manage/CustomerAPI";
import { getDistributor } from "../../Helpers/apiCalls/Distributor/DistributorApi";
// import AntDTable from '../../Components/AntDTable/AntDTable';
import Table from "../../Components/TableTemplate/Table";
import ProjectSaleModal from "../../Components/Modals/ProjectSaleModal";
import { searchProjectInv } from "../../Helpers/apiCalls/projectApi";
import moment from "moment";
import dayjs from "dayjs";

/**
 *  Franchise Register component
 */

// const { RangePicker } = DatePicker;
export default function ProjectSales() {
  // Get the current date
  const currentDate = moment();

  // Get the first day of the month
  const firstDayOfMonth = moment(currentDate)
    .startOf("month")
    .format("YYYY-MM-DD");
  // Get the last day of the month
  const lastDayOfMonth = moment(currentDate)
    .endOf("month")
    .format("YYYY-MM-DD");
  const [inactive, setInactive] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [allSales, setAllSales] = useState([]);
  const [projectInvoiceData, setProjectInvoiceData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [allDistributors, setAllDistributors] = useState([]);
  const [totalSummary, setTotalSummary] = useState({
    total_amount: 0,
    total_receivables: 0,
    total_expenses: 0,
    total_sales: 0,
  })
  const [showLoader, setShowLoader] = useState(false);
  //Expense Modal
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const handleCloseExpenseModal = () => setShowExpenseModal(false);

  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({
    // date_from: dayjs(firstDayOfMonth).format("YYYY-MM-DD"),
    // date_to: dayjs(lastDayOfMonth).format("YYYY-MM-DD"),
    date_from: null,
    date_to: null,
  });

  async function handleShowExpenseModal(data) {
    fetchProjectInv(data.row.project_id);
    setAllSales(data);
  }

  function OngoingPOBadges(row) {
    return (
      <ButtonGroup vertical>
        <>
          <Button
            className=""
            variant="outline-info"
            size="sm"
            onClick={() => handleShowExpenseModal(row)}
          >
            VIEW
          </Button>
        </>
      </ButtonGroup>
    );
  }

  async function fetchCustomers() {
    setShowLoader(true);
    setCustomers([]);

    const response = await getAllCustomer();
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      var customers = response.data.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setCustomers([{ label: "All Customers", value: "" }, ...customers]);
    }
    setShowLoader(false);
  }

  async function fetchProjects() {
    setShowLoader(true);
    setProjects([]);

    const response = await searchProject();
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      var projects = response.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setProjects([{ label: "All Projects", value: "" }, ...projects]);
    }
    setShowLoader(false);
  }

  async function fetchData() {
    setShowLoader(true);
    setTableData([]);
    setTotalSummary({
      total_amount: 0,
      total_receivables: 0,
      total_expenses: 0,
      total_sales: 0,
    })

    const response = await searchProjectSales(filterConfig);
    if (response.data) {
      var tempTotalReceivables = 0;
      var tempTotalExpenses = 0;
      var tempTotalAmount = 0;
      var tempTotalSales = 0;

      var allData = response.data.project_sales.map((data) => {
        tempTotalAmount += parseFloat(data.amount);
        tempTotalReceivables += parseFloat(data.receivable);
        tempTotalExpenses += parseFloat(data.project_expense);
        tempTotalSales += parseFloat(data.total_sales);

        var info = data;
        info.amount = numberFormat(data.amount);
        info.receivable = numberFormat(data.receivable);
        info.paid_amount = data.paid_amount
          ? numberFormat(data.paid_amount)
          : "0.00";
        info.total_sales = numberFormat(data.total_sales);
        info.project_expense = numberFormat(data.project_expense);
        return info;
      });

      setTableData(allData);
      setTotalSummary({
        total_amount: tempTotalAmount,
        total_receivables: tempTotalReceivables,
        total_expenses: tempTotalExpenses,
        total_sales: tempTotalSales,
      })
      // setTotalReceivables(tempTotalReceivables);
      // setTotalAmount(tempTotalAmount);
      // setTotalExpenses(tempTotalExpenses);
      // setTotalSales(tempTotalSales);
    }
    setShowLoader(false);
  }

  async function fetchProjectInv(projectId) {
    const response = await searchProjectInv(projectId);
    if (response.data) {
      setProjectInvoiceData(response.data.data);
      setShowExpenseModal(true);
    }
  }

  async function fetchDistributors() {
    setAllDistributors([]);
    const response = await getDistributor();
    if (response.data) {
      const res = response.data.data.map((row) => {
        return {
          value: row.id,
          label: row.name,
        };
      });
      setAllDistributors([{ value: "", label: "All Distributors" }, ...res]);
    }
  }

  const excelHeaders = [
    { label: "Project Name", key: "name" },
    { label: "Start Date", key: "start_date" },
    { label: "Customer", key: "customer_name" },
    { label: "Amount", key: "amount" },
    { label: "Paid Amount", key: "paid_amount" },
    { label: "Receivable", key: "receivable" },
    { label: "Project Expenses", key: "project_expense" },
    { label: "Total Profit", key: "total_sales" },
  ];

  function handleToCSV() {
    return (
      <CSVLink
        data={tableData}
        headers={excelHeaders}
        filename={"Projects.csv"}
        style={{ textDecoration: "none", color: "#ffffff" }}
      >
        Export to CSV
      </CSVLink>
    );
  }

  useEffect(() => {
    fetchProjects();
    fetchCustomers();
    fetchDistributors();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">PROJECT SALES</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <button className="add-btn text-center">{handleToCSV()}</button>
          </Col>
        </Row>

        <div className="tab-content">
          <div className="my-2 ms-2 PO-filters PI-filters d-flex">
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="All Projects"
              styles={selectDropdownStyle}
              options={projects}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, project_id: e.value };
                });
              }}
            />

            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Customer"
              styles={selectDropdownStyle}
              options={customers}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, customer_id: e.value };
                });
              }}
            />
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Distributor"
              styles={selectDropdownStyle}
              options={allDistributors}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, distributor_id: e.value };
                });
              }}
            />

            {/* <span className="me-3 align-middle mt-2">Date From:</span>
            <DatePicker
              placeholderText={"Select Date"}
              onChange={(date) => {
                if (date) {
                  setFilterConfig((prev) => {
                    return { ...prev, date_from: date.format("YYYY-MM-DD") };
                  });
                }
              }}
              placeholder={filterConfig.date_from}
              className="PI-date-btn me-3 form-control"
            />

            <span className="me-3 align-middle mt-2">To:</span>
            <DatePicker
              name="date_to"
              onChange={(date) => {
                if (date) {
                  setFilterConfig((prev) => {
                    return { ...prev, date_to: date.format("YYYY-MM-DD") };
                  });
                }
              }}
              className="PI-date-btn me-3 form-control"
              placeholder={filterConfig.date_to}
            /> */}
          </div>

          <div className="mt-4 mb-2 px-2 PO-filters d-flex justify-content-center">
            <span className="me-4 ml-4 align-middle ps-label">
              Total Amount: {numberFormat(totalSummary.total_amount)}
            </span>
            <span className="me-4 ml-4 align-middle ps-label">
              Total Receivables: {numberFormat(totalSummary.total_receivables)}
            </span>
            <span className="me-4 ml-4 align-middle ps-label">
              Total Expenses: {numberFormat(totalSummary.total_expenses)}
            </span>
            <span className="me-4 ml-4 align-middle ps-label">
              Total Sales: {numberFormat(totalSummary.total_sales)}
            </span>
          </div>
          <Table
            tableHeaders={[
              "PROJECT NAME",
              "START DATE",
              "CUSTOMER",
              "AMOUNT",
              "PAID AMOUNT",
              "RECEIVABLE",
              "PROJECT EXPENSE",
              "TOTAL PROFIT",
            ]}
            headerSelector={[
              "name",
              "start_date",
              "customer_name",
              "amount",
              "paid_amount",
              "receivable",
              "project_expense",
              "total_sales",
            ]}
            tableData={tableData}
            loading={showLoader}
            withAction={true}
            ActionBtn={(row) => OngoingPOBadges(row)}
          />
        </div>
      </div>
      <ProjectSaleModal
        show={showExpenseModal}
        onHide={handleCloseExpenseModal}
        data={projectInvoiceData}
        sales={allSales}
      ></ProjectSaleModal>
    </div>
  );
}
