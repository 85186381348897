import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {Modal, Form, Row, Col, Table} from "react-bootstrap"
import InputError from "../../Components/InputError/InputError";
import { validatePayment } from "../../Helpers/Validation/Franchise/FranchiseModalValidation";
import { formatAmount, numberFormat, toastStyle } from "../../Helpers/Utils/Common";
import Select from "react-select";
import ReactLoading from "react-loading";
import { getAllBanks } from "../../Helpers/apiCalls/banksAPi";
import moment from "moment";
import toast from "react-hot-toast";
import { addDistributorBillingPayment } from "../../Helpers/apiCalls/Distributor/DistributorApi";

export default function AddBillingPayment (props) {
    const navigate = useNavigate();
    const [isClicked, setIsClicked] = useState(false);
    const distributorBillingData = props.distributorBillingData;
    const [banks, setBanks] = useState([]);
    const [formErrors, setFormErrors] = useState({})
    const [clientDetails, setClientDetails] = useState([])
    
    const [paymentDetails, setPaymentDetails] = useState({})
    const handleChange = (e) => {
        const {name, value} = e.target;
        var newData = {...paymentDetails}
        newData[name] = value;
        setPaymentDetails(newData);
    }

    const handleEntries = (e, customerIndex, projectIndex) => {
        const {name, value} = e.target;
        setClientDetails((prevDetails) => {
          const updatedDetails = [...prevDetails];
          updatedDetails[customerIndex].projects[projectIndex][name] = value;
          return updatedDetails;
        });
      };

    const [isError, setIsError] = useState({
        payment_date: false,
        payment_type: false,
    })

    async function handleCreatePayment () {
        const errors = {}

        const finalProjectDetails = {
            distributor_billing_entry_ids: [],
            paid_amounts: [],
            grand_total: 0,
        }

        clientDetails.forEach((client, customerIndex) => {
            client.projects.forEach((project, projectIndex) => {
              if (!project.paid_amount) {
                if (!errors[customerIndex]) {
                  errors[customerIndex] = {};
                }
                errors[customerIndex][projectIndex] = {
                  ...errors[customerIndex][projectIndex],
                  paid_amount: 'Paid amount is required',
                };
              } else {
                finalProjectDetails.distributor_billing_entry_ids.push(client.id);
                finalProjectDetails.paid_amounts.push(project.paid_amount);
                finalProjectDetails.grand_total += Number(project.paid_amount)
              }
            });
          });

          setFormErrors(errors);

          if (Object.keys(errors).length===0) {
            const response = await addDistributorBillingPayment({...paymentDetails,...finalProjectDetails});
            if (response.data) {
                toast.success(response.data.response, {style: toastStyle()})
                props.hide();
                props.fetchData();
                // setTimeout(() => navigate("/distributor_billings"), 1000);
            } else {
                toast.error(response.error.data.messages.response, {style: toastStyle()})
            }
          }
    }

    useEffect(() => {
        async function fetchBanks() {
          const response = await getAllBanks();
          if (response.data) {
            var banks = response.data.data;
            var cleanedArray2 = banks.map((bank) => {
                var info = {};
                info.name = "to_bank_id";
                info.label = bank.bank_name;
                info.value = bank.id;
        
                return info;
            });
            setBanks(cleanedArray2);
          }
          
        }

        fetchBanks();
      }, []);

    useEffect(() => {
    if (distributorBillingData) {
      setPaymentDetails((prev) => ({...prev,
        billing_id: distributorBillingData.id,
        distributor_name: distributorBillingData.distributor_name,
        payment_date: moment().format("YYYY-MM-DD"), 
        payment_type: "cash",
    }))
        const inputArray =  distributorBillingData.distributor_billing_entries||[];
        const mergedMap = new Map();

        inputArray.forEach(item => {
            const customerID = item.customer_id;
            const project = {
                project_name: item.project_name,
                balance: item.balance,
                paid_amount: item.balance,
            };
        
            if (mergedMap.has(customerID)) {
            mergedMap.get(customerID).projects.push(project);
            } else {
            mergedMap.set(customerID, {
                customer_id: customerID,
                id: item.id,
                customer_name: item.customer_name,
                projects: [project],
            });
            }
        });
        const mergedArray = Array.from(mergedMap.values());
        setClientDetails(mergedArray);
        
        
    }
    
    }, [distributorBillingData]);
    
      const CustomerProjectsTable = () => {
        return (
          <div className="edit-purchased-items mb-5">
            <Table bordered>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Project</th>
                  <th className="text-right">Balance</th>
                  <th className="text-right">Paid Amount</th>
                </tr>
              </thead>
              <tbody>
                {clientDetails.map((client, customerIndex) => (
                  <React.Fragment key={customerIndex}>
                    {client.projects.map((project, projectIndex) => (
                      <tr key={projectIndex}>
                        {projectIndex === 0 && (
                          <td rowSpan={client.projects.length} className="w-30">
                            <Form.Control value={client.customer_name} disabled/>
                          </td>
                        )}
                        <td>
                          <Form.Control value={project.project_name} disabled/>
                        </td>
                        <td>
                          <Form.Control
                            value={project.balance}
                            name="balance"
                            disabled
                            className="text-end"
                            // onChange={(e) => handleEntries(e, customerIndex, projectIndex)}
                          />
                        </td>
                        <td>
                          <Form.Control
                            defaultValue={project.paid_amount}
                            type="number"
                            className="text-end"
                            name="paid_amount"
                            onChange={(e) => handleEntries(e, customerIndex, projectIndex)}
                          />
                          {formErrors[customerIndex] && formErrors[customerIndex][projectIndex].paid_amount && (
                            <div className="validity-error callout bottom">{formErrors[customerIndex][projectIndex].paid_amount}</div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
            {/* <Row className="mt-4">
              <Col xs={6}></Col>
              <Col xs={3} className="color-gray grand-total-text text-center">Grand Total</Col>
              <Col xs={1} className="grand-total-text">PHP</Col>
              <Col xs={2} className="text-end grand-total-text">
                {grandTotal
                  ? numberFormat(grandTotal)
                  : "0.00"}
              </Col>
            </Row> */}
          </div>
        );
      };

    return (
        <div>
          <Modal show={props.show} onHide={props.hide} size="lg" centered>
            <Modal.Header closeButton>
              <span className="page-title"> Add Payment</span>
            </Modal.Header>
            <Modal.Body className="return-body">
              <div>
                <Row className="">
                    <Col xs={6}>
                        <span className="edit-label">
                        Billing No.
                        </span>
                        <Form.Control
                            type="text"
                            name="invoice_no"
                            className="nc-modal-custom-text"
                            value={paymentDetails.billing_id||''}
                            disabled
                        />
                  </Col>
                  <Col xs={6}>
                        <span className="edit-label">
                        Distributor
                        </span>
                        <Form.Control
                            type="text"
                            name="invoice_no"
                            className="nc-modal-custom-text"
                            value={paymentDetails.distributor_name||''}
                            disabled
                        />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={6}>
                    <span className="edit-label">
                      Payment Date<span className="color-red"> *</span>
                    </span>
                    <Form.Control
                      type="date"
                      name="payment_date"
                      className="nc-modal-custom-text"
                      defaultValue={paymentDetails.payment_date}
                      onChange={(e) => handleChange(e)}
                    />
                    <InputError
                      isValid={isError.payment_date}
                      message={"Payment date is required"}
                    />
                  </Col>
                  <Col xs={6}>
                        <span className="edit-label">
                        Reference No.
                        </span>
                        <Form.Control   
                            type="text"
                            name="reference_no"
                            className="nc-modal-custom-text"
                            value={paymentDetails.reference_no}
                            onChange={handleChange}
                        />
                  </Col>
                  
                </Row>
                <Row className="mt-2">
                  <Col>
                    <span className="edit-label">Payment Method</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Check
                      inline
                      label="Cash"
                      name="payment_type"
                      value="cash"
                      type="radio"
                      defaultChecked
                      onClick={(e) => {
                        handleChange(e);
                      }}
                    />
                    <Form.Check
                      inline
                      label="Check"
                      name="payment_type"
                      type="radio"
                      value="check"
                      onClick={(e) => {
                        handleChange(e);
                      }}
                    />
                    <Form.Check
                      inline
                      label="Others"
                      name="payment_type"
                      value="others"
                      type="radio"
                      onClick={(e) => {
                        handleChange(e);
                      }}
                    />
                  </Col>
                  <InputError
                    isValid={isError.payment_type}
                    message={"Payment type is required"}
                  />
                  {/* <Col>
                    <span className="">Balance: {balance?formatAmount(balance):''}</span>
                  </Col> */}
                </Row>
              </div>
    
              {/* CHECK PAYMENT DETAILS */}
              {/* {paymentDetails.payment_type === "check" && (
                <>
                  <Row className="mt-3">
                    <Col xs={4}>
                      <span className="edit-label">Bank Name<span className="color-red"> *</span></span>
                      <Form.Control
                        type="text"
                        name="bank_name"
                        className="nc-modal-custom-text"
                        value={paymentDetails.bank_name}
                        onChange={(e) => handleChange(e)}
                      />
                      <InputError
                        isValid={isError.bank_name}
                        message={"Bank name is required"}
                      />
                    </Col>
                    <Col xs={4}>
                      <span className="edit-label">Check Date<span className="color-red"> *</span></span>
                      <Form.Control
                        type="date"
                        name="check_date"
                        className="nc-modal-custom-text"
                        defaultValue={paymentDetails.check_date}
                        onChange={(e) => handleChange(e)}
                      />
                      <InputError
                        isValid={isError.check_date}
                        message={"Check date is required"}
                      />
                    </Col>
                    <Col xs={4}>
                      <span className="edit-label">Check Number<span className="color-red"> *</span></span>
                      <Form.Control
                        type="text"
                        name="check_number"
                        className="nc-modal-custom-text"
                        defaultValue={paymentDetails.check_number}
                        onChange={(e) => handleChange(e)}
                      />
                      <InputError
                        isValid={isError.check_number}
                        message={"Check number is required"}
                      />
                    </Col>
                  </Row>
                </>
              )} */}
    
              {/* OTHERS PAYMENT DETAILS */}
              {/* {paymentDetails.payment_type === "others" && (
                <>
                  <Row className="mt-3">
                    <Col xs={6}>
                      <span className="edit-label">Payment Description<label className="badge-required">{` *`}</label></span>
                      <Form.Control
                        type="text"
                        name="payment_description"
                        className="nc-modal-custom-text"
                        defaultValue={paymentDetails.payment_description}
                        onChange={(e) => handleChange(e)}
                      />
                      <InputError isValid={isError.payment_description} message={"Payment description is required"}/>
                    </Col>
                    <Col xs={6}>
                      <span className="edit-label">
                        Reference Number
                        <label className="badge-required">{` *`}</label>
                      </span>
                      <Form.Control
                        type="text"
                        name="reference_no"
                        className="nc-modal-custom-text"
                        value={paymentDetails.reference_no}
                        onChange={(e) => handleChange(e)}
                      />
                      <InputError
                        isValid={isError.reference_no}
                        message={"Reference number is required"}
                      />
                    </Col>
                  </Row>
                </>
              )} */}

            <Row className="mt-4 pt-3">
                <span className="edit-label mb-2">CLIENTS</span>
                <CustomerProjectsTable/>
            </Row>
    
              <Row className="">
                <Col>
                  <span className="edit-label">
                    Remarks
                    <span className="edit-optional px-2">(Optional)</span>
                  </span>
                  <Form.Control
                    as="textarea"
                    name="remarks"
                    className="nc-modal-custom-text"
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="return-footer">
              <button type="button" className="button-secondary" onClick={()=>{props.hide(); setPaymentDetails({})}}>
                Cancel
              </button>
              {isClicked ? (
                <div className="button-primary d-flex justify-content-center">
                  <ReactLoading
                    type="bubbles"
                    color="#FFFFFF"
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                <button
                  type="button"
                  className="button-primary"
                  onClick={() => handleCreatePayment()}
                >
                  Pay
                </button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      );
}