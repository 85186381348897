import { getToken, getUser, getTime12 } from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";
import Moment from "moment";

//GET
export const getAllPartner = async () => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "partners/get_all_partner",
      {
        requester: getUser(),
        token: getToken(),
      }
    );
    return { data: response };
  } catch (error) {
    return { error: error.response };
  }
};

// GET SINGLE
export const getPartner = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "partners/get_partner",
      {
        requester: getUser(),
        token: getToken(),
        partner_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const searchPartner = async (name) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "partners/search",
      {
        requester: getUser(),
        token: getToken(),
        name: name,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const searchpartnerStatus = async (filterConfig) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "partner_operation_logs/get_all",
      {
        requester: getUser(),
        token: getToken(),
        status: filterConfig.status,
        partner_name: filterConfig.partner_name,
        partner_id: filterConfig.partner_id,
        date: filterConfig.date_from
          ? Moment(filterConfig.date_from).format("YYYY-MM-DD")
          : "",
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

//POST
export const createPartner = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "partners/create",
      {
        requester: getUser(),
        token: getToken(),
        name: data.name,
        company: data.company,
        address: data.address,
        contact_number: data.contact_number,
        email: data.email,
        tin_no: data.tin_no,
        contact_person: data.contact_person,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const updatePartner = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "partners/update",
      {
        requester: getUser(),
        token: getToken(),
        partner_id: data.id,
        name: data.name,
        company: data.company,
        address: data.address,
        contact_number: data.contact_number,
        email: data.email,
        tin_no: data.tin_no,
        contact_person: data.contact_person,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const deletePartner = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "partners/delete",
      {
        requester: getUser(),
        token: getToken(),
        partner_id: id,
      }
    );

    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};
