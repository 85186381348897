import { getToken, getUser, getTime12 } from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";
import Moment from "moment";

//GET
export const getAllCustomer = async () => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "customers/get_all_customer",
      {
        requester: getUser(),
        token: getToken(),
      }
    );
    return { data: response };
  } catch (error) {
    return { error: error.response };
  }
};

// GET SINGLE
export const getCustomer = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "customers/get_customer",
      {
        requester: getUser(),
        token: getToken(),
        customer_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const searchCustomer = async (name) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "customers/search",
      {
        requester: getUser(),
        token: getToken(),
        name: name,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const searchCustomerStatus = async (filterConfig) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "customer_operation_logs/get_all",
      {
        requester: getUser(),
        token: getToken(),
        status: filterConfig.status,
        customer_name: filterConfig.customer_name,
        customer_id: filterConfig.customer_id,
        date: filterConfig.date_from
          ? Moment(filterConfig.date_from).format("YYYY-MM-DD")
          : "",
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

//POST
export const createCustomer = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "customers/create",
      {
        requester: getUser(),
        token: getToken(),
        name: data.name,
        company: data.company,
        address: data.address,
        contact_number: data.contact_number,
        email: data.email,
        tin_no: data.tin_no,
        contact_person: data.contact_person,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const updateCustomer = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "customers/update",
      {
        requester: getUser(),
        token: getToken(),
        customer_id: data.id,
        name: data.name,
        company: data.company,
        address: data.address,
        contact_number: data.contact_number,
        email: data.email,
        tin_no: data.tin_no,
        contact_person: data.contact_person,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const deleteCustomer = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "customers/delete",
      {
        requester: getUser(),
        token: getToken(),
        customer_id: id,
      }
    );

    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};
