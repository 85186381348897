import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import {
  numberFormat,
  TokenExpiry,
  getTodayDateISO,
} from "../../../Helpers/Utils/Common";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { CSVLink } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";

import { getAllExpenseType } from "./../../../Helpers/apiCalls/expensetypesApi";
import { searchExpenseReport } from "./../../../Helpers/apiCalls/ExpensesReports/ExpenseReportApi";

export default function ExpenseReport() {
  const { datefrom, dateto } = useParams();
  let navigate = useNavigate();
  const [inactive, setInactive] = useState(true);
  const [filterConfig, setFilterConfig] = useState({
    date_from: new Date(datefrom),
    date_to: new Date(dateto),
  });
  const [showLoader, setShowLoader] = useState(false);
  const [allData, setAllData] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const excelHeaders = [
    { label: "DATE", key: "expense_date" },
    { label: "INVOICE No.", key: "particulars" },
    { label: "DESCRIPTION", key: "doc_no" },
    { label: "EXPENSE TYPE", key: "expense_type" },
    { label: "TOTAL AMOUNT", key: "expense_total" },
    { label: "PAID", key: "paid_amount" },
    { label: "BALANCE", key: "reference_no" },
  ];

  async function fetchExpenseTypes() {
    setShowLoader(true);
    setExpenseTypes([]);

    const response = await getAllExpenseType(filterConfig);
    if (response.error) {
      TokenExpiry(response.error);
    } else {
      var types = response.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setExpenseTypes([{ label: "All Expense Types", value: "" }, ...types]);
    }
    setShowLoader(false);
  }

  async function fetchData() {
    setShowLoader(true);
    setAllData([]);

    const response = await searchExpenseReport(filterConfig);
    if (response.error) {
    } else {
      var allBills = response.data.expense.map((bill) => {
        var info = bill;
        info.expense_date = Moment(bill.expense_date).format("MM-DD-YYYY");
        info.deposit_date = bill.deposit_date
          ? Moment(bill.deposit_date).format("MM-DD-YYYY")
          : "N/A";
        info.expense_total = numberFormat(bill.expense_total);
        info.paid_amount = numberFormat(bill.paid_amount);
        info.balance = numberFormat(bill.balance);
        return info;
      });

      var expense = response.data.summary
        ? response.data.summary.total_expense
        : "0";
      var paid = response.data.summary ? response.data.summary.total_paid : "0";
      var balance = response.data.summary
        ? response.data.summary.total_balance
        : "0";
      setTotalExpense(expense);
      setTotalPaid(paid);
      setTotalBalance(balance);

      setAllData(allBills);
    }

    setShowLoader(false);
  }

  React.useEffect(() => {
    fetchData();
  }, [filterConfig]);

  React.useEffect(() => {
    fetchExpenseTypes();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"EXPENSES REPORT"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> Expense Report </h1>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end mb-3">
            <div className="justify-content-center align-items-center ">
              <CSVLink
                className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                data={allData}
                headers={excelHeaders}
                target="_blank"
                filename={`${getTodayDateISO()} Expense Report`}
              >
                <span className="me-2">
                  <img width={20} height={20} src={downloadIcon} alt=""></img>
                </span>
                Download Excel
              </CSVLink>
            </div>
          </Col>
        </Row>

        <div className="tab-content mt-2">
          {/* filters */}
          <div className="my-2 px-2 PO-filters d-flex">
            <span className="me-1 align-middle mt-2 ps-label">Filter By:</span>
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Expense Type"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: state.isSelected ? "white" : "#744aa4",
                  borderRadius: "7px",
                  border: "0px",
                  minHeight: "20px",
                  maxHeight: "35px",
                }),
                input: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isSelected ? "white" : "white",
                }),
                dropdownIndicator: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "white",
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "white",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "white",
                }),
              }}
              // value={branch}
              options={expenseTypes}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, expense_type: e.value };
                });
              }}
            />
            {/* <Select
                            className="dropsearch-filter px-0 py-0 me-2"
                            classNamePrefix="react-select"
                            placeholder="Select Particular"
                            styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: state.isSelected ? "white" : "#744aa4",
                                borderRadius: "7px",
                                border: "0px",
                                minHeight: "20px",
                                maxHeight: "35px",
                                textAlign: "left",
                            }),
                            input: (baseStyles, state) => ({
                                ...baseStyles,
                                color: state.isSelected ? "white" : "white",
                            }),
                            dropdownIndicator: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "white",
                            }),
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "white",
                            }),
                            placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "white",
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                textAlign: "left",
                            }),
                            }}
                            // value={selectedSupplier}
                            // options={suppliers}
                            onChange={(e)=>{
                                setFilterConfig((prev) => {
                                    return { ...prev, "particular_id": e.value };
                                });
                            }}
                        /> */}
            <Form.Select
              type="select"
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Select Status..."
              name="payment_status"
              value={filterConfig.payment_status}
              onChange={(e) =>
                setFilterConfig((prev) => {
                  return { ...prev, payment_status: e.target.value };
                })
              }
            >
              <option value="">All</option>
              <option value="fully paid">Paid</option>
              <option value="unpaid">Unpaid</option>
            </Form.Select>

            <span className="me-1 align-middle mt-2 ps-label">From:</span>
            <DatePicker
              selected={filterConfig.date_from}
              name="date_from"
              placeholderText={"Select Date"}
              onChange={(date) => {
                setFilterConfig((prev) => {
                  return { ...prev, date_from: date };
                });
              }}
              fixedHeight
              className="PI-date-btn me-3 form-control"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={20}
              scrollableYearDropdown
            />

            <span className="me-1 align-middle mt-2 ps-label">To:</span>
            <DatePicker
              selected={filterConfig.date_to}
              name="date_to"
              placeholderText={"Select Date"}
              onChange={(date) => {
                setFilterConfig((prev) => {
                  return { ...prev, date_to: date };
                });
              }}
              fixedHeight
              className="PI-date-btn me-3 form-control"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={20}
              scrollableYearDropdown
            />
          </div>

          <div className="my-2 px-2 PO-filters d-flex justify-content-center">
            <span className="me-4 ml-4 align-middle ps-label">
              Total Expense: {numberFormat(totalExpense ? totalExpense : 0.0)}
            </span>
            <span className="me-4 ml-4 align-middle ps-label">
              Total Paid: {numberFormat(totalPaid ? totalPaid : 0.0)}
            </span>
            <span className="me-4 ml-4 align-middle ps-label">
              Total Balance: {numberFormat(totalBalance ? totalBalance : 0.0)}
            </span>
          </div>

          <div className="below">
            {/* table */}
            <Table
              tableHeaders={[
                // "-",
                "DATE",
                "INVOICE No.",
                "DESCRIPTION",
                "EXPENSE TYPE",
                "TOTAL AMOUNT",
                "PAID",
                "BALANCE",
              ]}
              headerSelector={[
                // "-",
                "expense_date",
                "doc_no",
                "description",
                "expense_type",
                "expense_total",
                "paid_amount",
                "balance",
              ]}
              tableData={allData}
              showLoader={showLoader}
            />
          </div>
          <div className="mb-2" />
        </div>
        <div className="d-flex justify-content-end pt-5 pb-3">
          <button
            type="button"
            className="button-secondary me-3"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
