import React, { useState, useRef, useEffect } from "react";
import {
  Col,
  Row,
  Form,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { isAdmin, toastStyle } from "../../Helpers/Utils/Common";

//components
import Table from "../../Components/TableTemplate/Table";
import DeleteModal from "../../Components/Modals/DeleteModal";
import AddModal from "../../Components/Modals/AddModal";
import ViewModal from "../../Components/Modals/ViewModal";
import EditModal from "../../Components/Modals/EditModal";
import InputError from "../../Components/InputError/InputError";
import Navbar from "../../Components/Navbar/Navbar";
//css
import "./Manage.css";
import "../../Components/Navbar/Navbar.css";

//api
import {
  createUser,
  getAllUsers,
  editUser,
  deleteUser,
} from "../../Helpers/apiCalls/usersApi";
import { validateUsers } from "../../Helpers/Validation/Manage/UsersValidation";

export default function Users() {
  const { id } = useParams();
  const [inactive, setInactive] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  // MODALS //
  // DELETE
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  // VIEW
  const [showViewUserModal, setShowViewUserModal] = useState(false);
  const handleShowViewUserModal = () => setShowViewUserModal(true);
  const handleCloseViewUserModal = () => setShowViewUserModal(false);

  // EDIT
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const handleShowEditUserModal = () => setShowEditUserModal(true);
  const handleCloseEditUserModal = () => {
    setShowEditUserModal(false);
    resetValues();
  };

  // ADD
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const handleShowAddUserModal = () => setShowAddUserModal(true);
  const handleCloseAddUserModal = () => {setShowAddUserModal(false); resetValues();}

  //API
  const [officeUsers, setOfficeUsers] = useState([]);
  const [userData, setUserData] = useState({});
  const [selectedID, setSelectedID] = useState("");
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    middle_name: " ",
    last_name: "",
    user_email: "",
    username: "",
    password: "",
    confirm_password: "",
  });

  function resetValues () {
    setUserDetails({});
  }

  const [filterConfig, setFilterConfig] = useState({
    tab: "office",
  });

  const handleTabSelect = (tabKey) => {
    setFilterConfig((prev) => {
      return { ...prev, tab: tabKey };
    });
  };

  const filteredData = officeUsers.filter(
    (item) =>
      item.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.username.toLowerCase().includes(searchText.toLowerCase())
  );

  const isInitialMount = useRef(true);
  const filterConfigKey = "manage-users-filterConfig";
  
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      setFilterConfig((prev) => {
        if (window.localStorage.getItem(filterConfigKey) != null) {
          handleTabSelect(
            JSON.parse(window.localStorage.getItem(filterConfigKey)).tab
          );
          return JSON.parse(window.localStorage.getItem(filterConfigKey));
        } else {
          return { ...prev };
        }
      });
    } else {
      window.localStorage.setItem(
        filterConfigKey,
        JSON.stringify(filterConfig)
      );
    }
  }, [filterConfig]);
 
  //REQUIRED ERROR HANDLING
  const [isError, setIsError] = useState({
    first_name: false,
    last_name: false,
    email: false,
    username: false,
    password: false,
    confirm_password: false,
  });

  const [isEditError, setIsEditError] = useState({
    first_name: false,
    last_name: false,
    email: false,
    username: false,
  });
 
  //ADD or CREATE USER
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //EDIT or UPDATE USER
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //onEdit
  function handleOnEdit() {
    handleCloseViewUserModal();
    handleShowEditUserModal();
  }

  //DROPDOWN
  function handleSelectChange(e, row) {
    setUserData(row);
    setSelectedID(row.id);
    if (e.target.value === "delete-user") {
      handleShowDeleteModal();
    } else if (e.target.value === "edit-user") {
      handleShowEditUserModal();
    } else if (e.target.value === "view-user") {
      handleShowViewUserModal();
    } else {
      handleShowDeleteModal();
    }
  }

  function ActionBtn(row) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action form-select"
        id={row.id}
        onChange={(e) => handleSelectChange(e, row)}
        value={""}
      >
        <option defaulValue selected hidden>
          Select
        </option>
        <option value="view-user" className="color-options">
          View
        </option>
        {isAdmin && (
          <option value="edit-user" className="color-options">
            Edit
          </option>
        )}
        {isAdmin && (
          <option value="delete-user" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

  //API CALL
  async function fetchAllUsers() {
    setShowLoader(true);
    const response = await getAllUsers();
    if (response.data) {
      var userList = response.data;
      response.data.map(
        (data, key) => (userList[key].middle_name = data.middle_name || " ")
      );
      response.data.map(
        (data, key) =>
          (userList[key].full_name =
            data.first_name + " " + data.middle_name + " " + data.last_name)
      );

      var branchData = userList.filter((user) => user.type === "branch");
      var otherData = userList.filter((user) => user.type !== "branch");

      setOfficeUsers(otherData);
    } 
    setShowLoader(false);
  }

  async function handleSaveUser() {
    if (validateUsers(userDetails, setIsError)) {
      if(userDetails.password === userDetails.confirm_password){
        setIsClicked(true);
        userDetails.employee_id = '';
        const response = await createUser(userDetails, "");
        if (response.data) {
          toast.success("Successfully created user!", {
            style: toastStyle(),
          });
          handleCloseAddUserModal();
          fetchAllUsers();
        } else {
          toast.error(response.error.data.messages.error, {
            style: toastStyle(),
          });
        }
        setIsClicked(false);
      }else{
        toast.error("Password does not match!", { style: toastStyle() });
      }
    } else {
      toast.error("Please fill in all fields!", { style: toastStyle() });
    }
  }

  async function handleEditUser() {
    if (validateUsers(userData, setIsEditError)) {
      setIsClicked(true);
      const response = await editUser(userData);
      if (response.data) {
        toast.success("Successfully updated user!", {
          style: toastStyle(),
        });
        handleCloseEditUserModal();
        fetchAllUsers();
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle()
        })
      }

      setIsClicked(false);
    }
  }

  async function removeUser(id) {
    const response = await deleteUser({id: userData.id, pin: userData.pin});
    if (response && response.data) {
      toast.success("Successfully deleted user!", {
        style: toastStyle(),
      });
      handleCloseDeleteModal();
      fetchAllUsers();
      // refreshPage();
    } else if (
      response &&
      response.error &&
      response.error.data &&
      response.error.data.messages &&
      response.error.data.messages.error
    ) {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      });
    } else {
    }
  }

  React.useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"MANAGE"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> USERS </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search Name..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search-bar"
            />
            <button
              className="add-btn"
              eventKey="office"
              onClick={handleShowAddUserModal}
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          {/* TABLE */}
          <Table
            tableHeaders={["FULL NAME", "USERNAME", "ACTIONS"]}
            headerSelector={["full_name", "username"]}
            tableData={filteredData}
            ActionBtn={(row) => ActionBtn(row)}
            showLoader={showLoader}
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="user"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onDelete={() => {removeUser(selectedID)}}
      />
      <AddModal
        title="USER"
        type="user"
        show={showAddUserModal}
        onHide={handleCloseAddUserModal}
        onSave={handleSaveUser}
        isClicked={isClicked}
        data={userDetails}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              FIRST NAME
              <span className="required"> * </span>
              <Form.Control
                type="text"
                name="first_name"
                className="nc-modal-custom-input"
                value={userData.first_name}
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.first_name}
                message={"First name is required"}
              />
            </Col>
            <Col>
              LAST NAME
              <span className="required"> * </span>
              <Form.Control
                type="text"
                name="last_name"
                value={userData.last_name}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.last_name}
                message={"Last name is required"}
              />
            </Col>
            <Col>
              MIDDLE NAME
              <Form.Control
                type="text"
                name="middle_name"
                value={userData.middle_name}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              USERNAME
              <span className="required"> * </span>
              <Form.Control
                type="text"
                name="username"
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.username}
                message={"Username is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              EMAIL
              <Form.Control
                type="email"
                name="user_email"
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>
            <Col>
              PASSWORD
              <span className="required"> * </span>
              <Form.Control
                type="password"
                name="password"
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.confirm_password}
                message={"Password is required"}
              />
            </Col>
            <Col>
              CONFIRM PASSWORD <span className="required"> * </span>
              <Form.Control
                type="password"
                name="confirm_password"
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.confirm_password}
                message={"password confirmation is required"}
              />
            </Col>
          </Row>
          {/* <Row className="nc-modal-custom-row">
            <Col>
              <span className="required"> * </span>
              <span className="required"> required fields </span>
            </Col>
          </Row> */}
        </div>
      </AddModal>

      <EditModal
        title="USER"
        type="user"
        show={showEditUserModal}
        onHide={handleCloseEditUserModal}
        onSave={handleEditUser}
        data={userData}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              FIRST NAME<span className="required"> * </span>
              <Form.Control
                type="text"
                name="first_name"
                defaultValue={userData.first_name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isEditError.first_name}
                message={"First name is required"}
              />
            </Col>
            <Col>
              LAST NAME<span className="required"> * </span>
              <Form.Control
                type="text"
                name="last_name"
                defaultValue={userData.last_name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isEditError.last_name}
                message={"Last name is required"}
              />
            </Col>
            <Col>
              MIDDLE NAME
              <Form.Control
                type="text"
                name="middle_name"
                defaultValue={userData.middle_name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              USERNAME
              <span className="required"> * </span>
              <Form.Control
                type="text"
                name="username"
                defaultValue={userData.username}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isEditError.username}
                message={"Username is required"}
              />
            </Col>

          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              EMAIL
              <Form.Control
                type="email"
                name="email"
                defaultValue={userData.email}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
            <Col>
              RESET PASSWORD
              <Form.Control
                type="text"
                name="password"
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        show={showViewUserModal}
        onHide={handleCloseViewUserModal}
        onEdit={handleOnEdit}
      >
        <div className="mt-1">
            <span className="custom-modal-body-title-user-details">
              USER DETAILS
            </span>
          <Row className="nc-modal-custom-row mt-3">
            <Col>
              FIRST NAME
              <Form.Control
                type="text"
                name="first_name"
                defaultValue={userData.first_name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              LAST NAME
              <Form.Control
                type="text"
                name="last_name"
                defaultValue={userData.last_name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              MIDDLE NAME
              <Form.Control
                type="text"
                name="middle_name"
                defaultValue={userData.middle_name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              USERNAME
              <span className="required"> * </span>
              <Form.Control
                type="text"
                name="username"
                defaultValue={userData.username}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              EMAIL
              <Form.Control
                type="email"
                name="email"
                defaultValue={userData.email}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
    </div>
  );
}
