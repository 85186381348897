import { getToken, getUser, getTime12 } from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";
import Moment from "moment";
import formData from "react-form-data";

//GET
export const getAllProjectExpense = async () => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_expenses/get_all_project_expense",
      {
        requester: getUser(),
        token: getToken(),
      }
    );
    return { data: response };
  } catch (error) {
    return { error: error.response };
  }
};

// GET SINGLE
export const getProjectExpense = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_expenses/get_project_expense",
      {
        requester: getUser(),
        token: getToken(),
        project_expense_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};
export const updateStatusProjectExpense = async (id, project_expense_status) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "project_expenses/update_status",
      {
        requester: getUser(),
        token: getToken(),
        project_expense_id: id,
        status: project_expense_status,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};
export const searchProjectExpense = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_expenses/search",
      {
        requester: getUser(),
        token: getToken(),
        ...data
        // name: data.name,
        // project_id: data.project_id,
        // partner_id: data.partner_id,
        // status: data.status,
        // project_name: data.project_name,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

// export const searchProjectStatusExpense = async (filterConfig) => {
//   try {
//     const response = await getAPICall(
//       process.env.REACT_APP_LINK + "project_operation_logs/get_all",
//       {
//         requester: getUser(),
//         token: getToken(),
//         status: filterConfig.status,
//         branch_type: filterConfig.branch_type,
//         project_name: filterConfig.project_name,
//         project_expense_id: filterConfig.project_expense_id,
//         date: filterConfig.date_from
//           ? Moment(filterConfig.date_from).format("YYYY-MM-DD")
//           : "",
//       }
//     );
//     return { data: response.data };
//   } catch (error) {
//     return { error: error.response };
//   }
// };

//POST
export const createProjectExpense = async (data, files) => {
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("project_id", data.project_id);
    form_data.append("project_price", data.project_price);
    form_data.append("expense_type_id", data.expense_type_id);
    // form_data.append("partner_id", data.partner_id);
    form_data.append("partner_name", data.partner_name);
    form_data.append("remarks", data.remarks);
    form_data.append("amount", data.amount);
    form_data.append("other_fees", data.other_fees);
    form_data.append("grand_total", data.grand_total);
    form_data.append("project_expense_date", data.project_expense_date);
    form_data.append("supplier_id", data.supplier_id);

    files.map((file) => {
        form_data.append('file[]', file);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "project_expenses/create", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }

    //   try {
    //     const response = await postAPICall(
    //       process.env.REACT_APP_LINK + "project_expense/create",
    //       {
    //         requester: getUser(),
    //         token: getToken(),
    //         name: data.name,
    //         project_date: data.project_date,
    //         start_date: data.start_date,
    //         customer_id: data.customer_id,
    //         address: data.address,
    //         company: data.company,
    //         contact_person: data.contact_person,
    //         contact_number: data.contact_number,
    //         project_type: data.project_type,
    //         project_price: data.project_price,
    //         taxes: data.taxes,
    //         other_fees: data.other_fees,
    //         grand_total: data.grand_total,
    //       }
    //     );
    //     return { data: response.data };
    //   } catch (error) {
    //     return { error: error.response };
    //   }
};

export const updateProjectExpense = async (data, files) => {
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("project_expense_id", data.id);
    form_data.append("project_id", data.project_id);
    form_data.append("project_price", data.project_price);
    form_data.append("expense_type_id", data.expense_type_id);
    form_data.append("partner_name", data.partner_name);
    form_data.append("remarks", data.remarks);
    form_data.append("amount", data.amount);
    form_data.append("other_fees", data.other_fees);
    form_data.append("grand_total", data.grand_total);
    form_data.append("project_expense_date", data.project_expense_date);
    form_data.append("supplier_id", data.supplier_id);

    files.map((file) => {
        form_data.append('file[]', file);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "project_expenses/update", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }

    // try {
    //     const response = await postAPICall(
    //     process.env.REACT_APP_LINK + "project_expense/update",
    //     {
    //         requester: getUser(),
    //         token: getToken(),
    //         project_id: data.id,
    //         name: data.name,
    //         project_date: data.project_date,
    //         start_date: data.start_date,
    //         customer_id: data.customer_id,
    //         address: data.address,
    //         company: data.company,
    //         contact_person: data.contact_person,
    //         contact_number: data.contact_number,
    //         project_type: data.project_type,
    //         project_price: data.project_price,
    //         taxes: data.taxes,
    //         other_fees: data.other_fees,
    //         grand_total: data.grand_total,
    //     }
    //     );
    //     return { data: response.data };
    // } catch (error) {
    //     return { error: error.response };
    // }
};

export const deleteProjectExpense = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_expenses/delete",
      {
        requester: getUser(),
        token: getToken(),
        project_expense_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};
