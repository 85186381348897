import React, { useState, useRef, useEffect } from "react";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";

//components
import Table from "../../Components/TableTemplate/Table";
import Navbar from "../../Components/Navbar/Navbar";
import DeleteModal from "../../Components/Modals/DeleteModal";
import AddModal from "../../Components/Modals/AddModal";
import EditModal from "../../Components/Modals/EditModal";
import ViewModal from "../../Components/Modals/ViewModal";

//css
import "./Manage.css";
import "../../Components/Navbar/Navbar.css";

import { branchesMockData } from "../../Helpers/mockData/mockData";
import {
  createProject,
  deleteProject,
  getAllProjects,
  searchProject,
  updateProject,
} from "../../Helpers/apiCalls/Manage/Projects";
import toast from "react-hot-toast";
import {
  toastStyle,
  refreshPage,
  isAdmin,
  formatDate,
  TokenExpiry,
  capitalizeFirstLetter,
} from "../../Helpers/Utils/Common";
import { validateBranches } from "../../Helpers/Validation/Manage/BanchesValidation";
import InputError from "../../Components/InputError/InputError";
import { useNavigate } from "react-router-dom";

export default function Branches() {
  const [inactive, setInactive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [option, setOption] = useState("Select");
  const [isClicked, setIsClicked] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  // let navigate = useNavigate();
  // const [addTo, setAddTo] = useState("");

  // MODALS //
  // DELETE
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  // VIEW
  const [showViewProjectModal, setShowViewProjectModal] = useState(false);
  const handleShowViewProjectModal = () => setShowViewProjectModal(true);
  const handleCloseViewProjectModal = () => setShowViewProjectModal(false);

  // EDIT
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const handleShowEditProjectModal = () => setShowEditProjectModal(true);
  const handleCloseEditProjectModal = () => setShowEditProjectModal(false);

  // ADD
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const handleShowAddProjectModal = () => setShowAddProjectModal(true);
  const handleCloseAddProjectModal = () => {
    setShowAddProjectModal(false);
    // setAddTo("");
    // setShowAddProjectModal(false);
  };

  // const handleAddSelect = (e) => {
  //   setAddTo(e);
  // };

  //REQUIRED ERROR HANDLING
  const [isError, setIsError] = useState({
    name: false,
    company: false,
    address: false,
    phone_no: false,
    tin_no: false,
    contact_person: false,
  });

  const [isErrorEdit, setIsErrorEdit] = useState({
    name: false,
    company: false,
    address: false,
    phone_no: false,
    tin_no: false,
    contact_person: false,
  });

  //API
  const [projectData, setProjectData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [selectedID, setSelectedID] = useState("");
  const [searchProjectName, setSearchProjectName] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [addProjectData, setAddProjectData] = useState({
    name: "",
    company: "",
    address: "",
    phone_no: "",
    tin_no: "",
    contact_person: "",
  });

  const [editProjectData, setEditProjectData] = useState({
    name: "",
    company: "",
    address: "",
    phone_no: "",
    tin_no: "",
    contact_person: "",
  });

  // DELETE or REMOVE USER
  // function handleDeleteProject() {
  //   removeProject(selectedID);
  // }

  //onEdit
  // function handleOnEdit() {
  //   handleCloseViewProjectModal();
  //   handleShowEditProjectModal();
  // }

  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setAddProjectData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditProjectData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //DROPDOWN
  function handleSelectChange(e, row) {
    setShowLoader(true);
    setSelectedRow(row);
    if (e.target.value === "delete-project") {
      handleShowDeleteModal();
    } else if (e.target.value === "edit-project") {
      setEditProjectData(row);
      handleShowEditProjectModal();
    } else if (e.target.value === "view-project") {
      handleShowViewProjectModal();
    } else {
      handleShowDeleteModal();
    }
    setShowLoader(false);
  }

  function ActionBtn(row) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action form-select"
        id={row}
        onChange={(e) => handleSelectChange(e, row)}
        value={option}
      >
        <option defaulValue selected hidden>
          Select
        </option>
        <option value="view-project" className="color-options">
          View
        </option>
        {isAdmin && (
          <option value="edit-project" className="color-options">
            Edit
          </option>
        )}
        {isAdmin && (
          <option value="delete-project" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

  async function fetchAllProject() {
    setShowLoader(true);
    const response = await getAllProjects();
    if (response.data) {
      setAllData(response.data);
    } else {
      setAllData([]);
    }
    setShowLoader(false);
  }

  const [searchName, setSearchName] = useState("");

  // SEARCH USER
  function handleOnSearch(e) {
    setSearchName(e.target.value);
  }

  async function create() {
    if (validateBranches(addProjectData, setIsError)) {
      setIsClicked(true);
      const response = await createProject(addProjectData);
      if (response) {
        if (response?.data?.status === "success") {
          toast.success("Successfully added project!", {
            style: toastStyle(),
          });
          setTimeout(() => refreshPage(), 1000);
        }
        if (response?.error?.data?.messages?.name) {
          toast.error(response.error.data.messages.name, {
            style: toastStyle(),
          });
        }
        if (response?.error?.data?.messages?.account_name) {
          toast.error(response.error.data.messages.account_name, {
            style: toastStyle(),
          });
        }
      }
    }
  }

  async function searchProjects() {
    setProjectData([]);
    setShowLoader(true);
    const response = await searchProject(searchProjectName);
    if (response && response.data && response.data.data) {
      let result = response.data.data.map((a) => {
        return {
          name: a.name,
          company: a.company,
          address: a.address,
          phone_no: a.phone_no,
          tin_no: a.tin_no,
          contact_person: a.contact_person,
          action_btn: ActionBtn(a),
        };
      });
      setProjectData(result);
    }
    setShowLoader(false);
  }

  async function del() {
    const response = await deleteProject(selectedRow.id);
    if (response) {
      if (response?.data?.status === "success") {
        toast.success(response.data.response, {
          style: toastStyle(),
        });
        setTimeout(() => refreshPage(), 1000);
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        });
      }
    }
  }

  async function editBranch() {
    if (validateBranches(editProjectData, setIsErrorEdit)) {
      const response = await updateProject(editProjectData);

      if (response) {
        if (response?.data?.status === "success") {
          toast.success(response.data.response, {
            style: toastStyle(),
          });
          setTimeout(() => refreshPage(), 1000);
        } else {
          toast.error(response.error.data.messages.error, {
            style: toastStyle(),
          });
        }
      }
    }
  }

  React.useEffect(() => {
    fetchAllProject();
  }, []);

  React.useEffect(() => {
    searchProjects();
  }, [searchProjectName]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"MANAGE"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-5">
          <Col xs={6}>
            <h1 className="page-title"> PROJECTS </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="text"
              name="name"
              className="search-bar"
              placeholder="Search project name..."
              onKeyPress={(e) =>
                e.key === ("Enter" || "NumpadEnter") && handleOnSearch(e)
              }
            ></input>
            <button className="add-btn" onClick={handleShowAddProjectModal}>
              Add
            </button>
          </Col>
        </Row>
        <div className="tab-content">
          <Table
            tableHeaders={[
              "NAME",
              "COMPANY",
              "ADDRESS",
              "PHONE NUMBER",
              // "TIN NUMBER",
              // "CONTACT PERSON",
              "ACTIONS",
            ]}
            headerSelector={[
              "name",
              "company",
              "address",
              "phone_no",
              // "tin_no",
              // "contact_person",
              "action_btn",
            ]}
            tableData={projectData}
            showLoader={showLoader}
            withActionData={true}
            // ActionBtn={(row) => ActionBtn(row)}
          />
        </div>
        {/* </Tab>
        </Tabs> */}
      </div>

      {/* MODALS */}
      <DeleteModal
        text="branch"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onDelete={() => del()}
      />
      <AddModal
        title="PROJECT"
        size="lg"
        type="branch"
        show={showAddProjectModal}
        onHide={handleCloseAddProjectModal}
        onSave={() => create()}
        isClicked={isClicked}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              PROJECT NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={addProjectData.name}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.name}
                message={"project name is required"}
              />
            </Col>
            <Col>
              COMPANY
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="company"
                value={addProjectData.company}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.company}
                message={"project name is required"}
              />
            </Col>
            <Col>
              ADDRESS
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="address"
                value={addProjectData.address}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.address}
                message={"Address is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              PHONE NUMBER
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="phone_no"
                value={addProjectData.phone_no}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.phone_no}
                message={"Address is required"}
              />
            </Col>
            <Col>
              TIN NUMBER
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="tin_no"
                value={addProjectData.tin_no}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.tin_no}
                message={"Contact person is required"}
              />
            </Col>
            <Col>
              CONTACT PERSON
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="contact_person"
                value={addProjectData.contact_person}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
              <InputError
                isValid={isError.contact_person}
                message={"Contact person is required"}
              />
            </Col>
          </Row>
        </div>
      </AddModal>
      <EditModal
        title="BRANCH"
        size="lg"
        type="branch"
        show={showEditProjectModal}
        onHide={handleCloseEditProjectModal}
        onSave={() => editBranch()}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              PROJECT NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={editProjectData.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isErrorEdit.name}
                message={"Branch name is required"}
              />
            </Col>
            <Col>
              COMPANY
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="company"
                value={editProjectData.company}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isError.company}
                message={"project name is required"}
              />
            </Col>
            <Col>
              ADDRESS
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="address"
                value={editProjectData.address}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isErrorEdit.address}
                message={"Address is required"}
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row mt-0">
            <Col>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="phone_no"
                value={editProjectData.phone_no}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
            <Col>
              TIN NUMBER
              <Form.Control
                type="text"
                name="tin_no"
                value={editProjectData.tin_no}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>
            <Col>
              CONTACT PERSON
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="contact_person"
                value={editProjectData.contact_person}
                className="nc-modal-custom-input-edit"
                oonChange={(e) => handleEditChange(e)}
                required
              />
              <InputError
                isValid={isError.contact_person}
                message={"Contact person is required"}
              />
            </Col>
          </Row>
        </div>
      </EditModal>
      <ViewModal
        withButtons
        show={showViewProjectModal}
        onHide={handleCloseViewProjectModal}
        // onEdit={handleOnEdit}
        onSave={() => alert("Save")}
      >
        <div className="mt-0">
          <div className="col-sm-12 m-0 space">
            <div className="mt-3 container-wrapper">
              <Row className="nc-modal-custom-row-view">
                <Col xl={3} className="nc-modal-custom-row-details">
                  PROJECT NAME
                  <Row className="nc-modal-custom-row">
                    <Col> {selectedRow.name} </Col>
                  </Row>
                </Col>
                <Col xl={6} className="nc-modal-custom-row-details">
                  COMPANY
                  <Row className="nc-modal-custom-row">
                    <Col> {selectedRow.company} </Col>
                  </Row>
                </Col>
                <Col xl={6} className="nc-modal-custom-row-details">
                  ADDRESS
                  <Row className="nc-modal-custom-row">
                    <Col> {selectedRow.address} </Col>
                  </Row>
                </Col>
                <Col xl={3} className="nc-modal-custom-row-details">
                  PHONE NUMBER
                  <Row className="nc-modal-custom-row">
                    <Col> {selectedRow.phone_no || "N/A"} </Col>
                  </Row>
                </Col>
                <Row className="nc-modal-custom-row-view mt-3">
                  <Col xl={3} className="nc-modal-custom-row-details">
                    TIN NUMBER
                    <Row className="nc-modal-custom-row">
                      <Col>{selectedRow?.tin_no}</Col>
                    </Row>
                  </Col>
                  <Col xl={3} className="nc-modal-custom-row-details">
                    CONTACT PERSON
                    <Row className="nc-modal-custom-row">
                      <Col>{selectedRow?.contact_person}</Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
            </div>
          </div>
        </div>
      </ViewModal>
    </div>
  );
}
